import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ColissimoWeight } from '../../../_commons/models/colissimo-weight';
import { ColissimoPriceService } from '../../../_commons/services/colissimo-price/colissimo-price.service';

import { ToPricePipe } from '../../../_commons/pipes/to-price/to-price.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AdminSettingsColissimoModalComponent } from './admin-settings-colissimo-modal/admin-settings-colissimo-modal.component';

@Component({
	selector: 'app-admin-settings-colissimo',
	standalone: true,
	imports: [
		MatButtonModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatTabsModule,
		MatTooltipModule,
		ToPricePipe,
	],
	templateUrl: './admin-settings-colissimo.component.html',
	styleUrl: './admin-settings-colissimo.component.scss'
})
export class AdminSettingsColissimoComponent implements OnInit {

	public colissimoPrices: ColissimoWeight[] = [];

	public displayedColumns: string[] = [
		"id",
		"weight",
		"priceHome",
		"priceRelay",
		"actions"
	];

	public isLoading: boolean = false;

	constructor(
		private dialog: MatDialog,
		private _colissimoPrices: ColissimoPriceService
	) {}
	
	public async ngOnInit(): Promise<void> {
		this.isLoading = true;
		this.colissimoPrices = await this._colissimoPrices.getColissimoPrices();
		this.isLoading = false;
	}

	public openDialog(): void {
		this.dialog.open(AdminSettingsColissimoModalComponent, {
			disableClose: true
		})
		.afterClosed().subscribe(async (data) => {
			if(data.success)
				this.colissimoPrices = await this._colissimoPrices.getColissimoPrices();
		});
	}

}
