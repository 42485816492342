import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SITEMAP, SiteMap } from '../_commons/sitemap';

@Component({
	selector: 'app-sign',
	standalone: true,
	imports: [ RouterModule ],
	templateUrl: './sign.component.html',
	styleUrl: './sign.component.scss'
})
export class SignComponent {

	public sitemap: SiteMap = SITEMAP;

}
