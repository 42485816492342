<div class="cb-address-selector" [class]="this.disabled ? 'disabled' : ''">
	<mat-radio-group
		class="cb-address-selector-addresses"
		[disabled]="this.disabled || this.deletion"
	>
		@if (this.deletion) {
			<div class="cb-address-selector-loading">
				<mat-progress-spinner diameter="48" mode="indeterminate"></mat-progress-spinner>
			</div>
		}

		@for (a of this.addresses; track address) {
			<mat-radio-button
				class="cb-address-selector-addresses-address"
				[value]="a"
				[disabled]="!this.selectable"
				[checked]="(!this.address && a.isDefault) || this.address?.id === a.id"
				(click)="!this.disabled ? this.update(a) : null"
			>
				<app-cb-address-item
					[address]="a"
					[disabled]="this.disabled || this.deletion"
					[options]="this.options"
					[beforeDelete]="this.startDeletion"
					[afterDelete]="this.doneDeletion"
				></app-cb-address-item>
			</mat-radio-button>
		}

		@empty {
			<p>aucune adresse n'a été trouvée sur votre profile</p>
		}
	</mat-radio-group>

	@if(this.addAddress) {
		<div class="cb-address-selector-buttons">
			<button
				mat-flat-button
				color="none"
				(click)="this.openDialog()"
				[disabled]="this.disabled || this.deletion"
			>
				<mat-icon class="pow-icon">add</mat-icon>
				<p>ajouter une nouvelle adresse</p>
			</button>
		</div>
	}
</div>
