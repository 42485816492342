import { ViewEncapsulation } from "@angular/core";
import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";

@Component({
	selector: "app-account-details",
	standalone: true,
	imports: [RouterOutlet],
	templateUrl: "./account-details.component.html",
	styleUrl: "./account-details.component.scss",
	encapsulation: ViewEncapsulation.None,
})
export class AccountDetailsComponent {}
