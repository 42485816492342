import { HttpHeaders, HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_URL } from "../../../constants";
import { User } from "../../../models/user";
import { HEADERS } from "../headers";

@Injectable({
	providedIn: "root",
})
export class ApiUserService {

	private API_URL: string = API_URL;
	private headers: HttpHeaders = HEADERS;
	private USER_PATH: string = "user";

	constructor(private http: HttpClient) {}

	public async getAll(
		token: string
	): Promise<HttpResponse<User[]> | undefined> {
		return this.http
			.get<User[]>(`${this.API_URL}/${this.USER_PATH}/`, {
				headers: this.headers.append("token", token),
				observe: "response",
			})
			.toPromise();
	}

	public async getById(
		token: string,
		id: number
	): Promise<HttpResponse<User> | undefined> {
		return this.http
			.get<User>(`${this.API_URL}/${this.USER_PATH}/${id}`, {
				headers: this.headers.append("token", token),
				observe: "response",
			})
			.toPromise();
	}
}
