<div class="cb-btn-algn">
	<cb-app-button mat-button text="Enregistrer" type="submit"></cb-app-button>
</div>
<div class="cb-account-details-index">
	<div class="cb-account-details-index-ctnr">
		<div class="cb-account-details-index-algn">
			<div class="cb-title-ctnr">
				<h2 class="cb-title">Vos informations personnelles</h2>
			</div>

			@if(this.user){
			<form
				class="cb-form"
				[formGroup]="this.profileAccount"
				(submit)="this.saveChanges()"
			>
				<div class="cb-form-short-field-ctnr">
					<div class="cb-form-short-field-algn">
						<mat-form-field
							class="cb-form-short-field cb-form-short-input-left"
							appearance="outline"
						>
							<mat-label>Nom</mat-label>
							<input
								[(ngModel)]="this.user.profile.lastName"
								class="cb-form-input"
								matInput
								#lastName
								type="text"
								placeholder="Nom"
								formControlName="lastName"
							/>
							@if(this.profileAccount.controls['lastName'].hasError('required')){
							<mat-error> Un <strong>nom</strong> est requis </mat-error>
							}
						</mat-form-field>
					</div>

					<div class="cb-form-short-field-algn">
						<mat-form-field
							class="cb-form-short-field cb-form-short-input-right"
							appearance="outline"
						>
							<mat-label>Prénom</mat-label>
							<input
								class="cb-form-input"
								[(ngModel)]="this.user.profile.firstName"
								matInput
								#firstName
								type="text"
								placeholder="Prénom"
								formControlName="firstName"
							/>

							@if(this.profileAccount.controls['firstName'].hasError('required')){
							<mat-error> Un <strong>prénom</strong> est requis </mat-error>
							}
						</mat-form-field>
					</div>
				</div>

				<mat-form-field class="cb-form-long-field" appearance="outline">
					<mat-label>Date D'anniversaire</mat-label>
					<input
						class="cb-form-input"
						matInput
						#birthday
						type="date"
						placeholder="Date d'anniversaire"
						formControlName="birthday"
					/>
					@if(this.profileAccount.controls['birthday'].hasError('required')) {
					<mat-error>
						Une <strong>date d'anniversaire</strong> est requise
					</mat-error>
					}
				</mat-form-field>

				@if(!this.isProfileAccountFormEnable) {
				<div class="cb-btn-algn">
					<app-cb-button
						mat-flat-button
						color="primary"
						text="Modifier"
						(click)="this.enableInput()"
					></app-cb-button>
				</div>
				} @if(this.isProfileAccountFormEnable ) {

				<div class="edit-buttons">
					<div class="cb-btn-algn">
						<app-cb-button
							mat-button
							text="Enregistrer"
							type="submit"
							color="primary"
						></app-cb-button>
					</div>
					<div class="cb-btn-algn">
						<app-cb-button
							mat-button
							text="Annuler"
							(click)="this.cancelEdit()"
							color="primary"
						></app-cb-button>
					</div>
				</div>
				}
			</form>
			}

			<div class="cb-title-ctnr">
				<h3 class="cb-title">Modifier votre mot de passe</h3>
			</div>

			<div class="cb-btn-algn">
				<app-cb-button
					#buttonElement
					mat-flat-button
					class="cb-btn"
					[routerLink]="[this.sitemap.accountDetailsResetPassword.route]"
					color="primary"
					text="Modifier"
				></app-cb-button>
			</div>

			<div class="cb-title-ctnr">
				<h3 class="cb-title">Vos adresses de livraison</h3>
			</div>

			<div class="cb-address-ctnr">
				<div class="cb-address-align">
					@if(this.user && this.addresses){
						<app-cb-address-selector
							[disabled]="false"
							[selectable]="false"
							[mail]="this.user.email"
							[addresses]="this.addresses"
						></app-cb-address-selector>
					}
				</div>
			</div>
		</div>
	</div>
</div>
