import { Injectable } from "@angular/core";
import { API_URL } from "../../../constants";
import { HttpHeaders, HttpClient, HttpResponse } from "@angular/common/http";
import { OrderContentDto } from "../../../dtos/order-content.dto";
import { OrderDto } from "../../../dtos/order.dto";
import { Order } from "../../../models/order";
import { HEADERS } from "../headers";

@Injectable({
	providedIn: "root",
})
export class ApiOrderService {

	private API_URL: string = API_URL;
	private headers: HttpHeaders = HEADERS;
	private ORDER_PATH: string = "order";

	constructor(private http: HttpClient) {}

	public async getAll(
		token: string
	): Promise<HttpResponse<Order[]> | undefined> {
		return this.http
			.get<Order[]>(`${this.API_URL}/${this.ORDER_PATH}/all`, {
				headers: this.headers.append("token", token),
				observe: "response",
			})
			.toPromise();
	}

	public async getAllByUser(
		token: string
	): Promise<HttpResponse<Order[]> | undefined> {
		return this.http
			.get<Order[]>(`${this.API_URL}/${this.ORDER_PATH}/`, {
				headers: this.headers.append("token", token),
				observe: "response",
			})
			.toPromise();
	}

	public async getById(
		token: string,
		id: number
	): Promise<HttpResponse<Order> | undefined> {
		return this.http
			.get<Order>(`${this.API_URL}/${this.ORDER_PATH}/${id}`, {
				headers: this.headers.append("token", token),
				observe: "response",
			})
			.toPromise();
	}

	public async add(
		token: string,
		orderContentDto: OrderContentDto
	): Promise<HttpResponse<Order> | undefined> {
		console.log(token);
		return this.http
			.post<Order>(`${this.API_URL}/${this.ORDER_PATH}/`, orderContentDto, {
				headers: this.headers.append("token", token),
				observe: "response",
			})
			.toPromise();
	}

	public async update(
		token: string,
		id: number,
		orderDto: OrderDto
	): Promise<HttpResponse<Order> | undefined> {
		return this.http
			.put<Order>(`${this.API_URL}/${this.ORDER_PATH}/${id}`, orderDto, {
				headers: this.headers.append("token", token),
				observe: "response",
			})
			.toPromise();
	}

	public async delete(
		token: string,
		id: number
	): Promise<HttpResponse<void> | undefined> {
		return this.http
			.delete<void>(`${this.API_URL}/${this.ORDER_PATH}/${id}`, {
				headers: this.headers.append("token", token),
				observe: "response",
			})
			.toPromise();
	}
}
