import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router, RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";

import { SITEMAP, SiteMap } from "./_commons/sitemap";
import { ApiStatus } from "./_commons/models/api-status";
import { User } from "./_commons/models/user";

import { ApiService } from "./_commons/services/api/api.service";
import { AccountService } from "./_commons/services/account/account.service";

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [
		RouterModule,
		MatButtonModule,
		MatIconModule,
		MatFormFieldModule,
		MatTooltipModule
	],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {

	public title: string = 'asc-carrementbon';
	public version: string = '1.0.0';

	public user!: User;
	public sitemap: SiteMap = SITEMAP;
	public adminPath: boolean = false;
	public isAdmin: boolean = false;

	constructor(
		private router: Router,
		private _account: AccountService,
		private _api: ApiService,
	) {
		this._account.currentUser.subscribe((user: User | null) => {
			this.isAdmin = user?.role.isAdmin || false;
			this.user = user!;
		});
		this.router.events.subscribe(() => this.adminPath = this.router.url.includes('/admin'));
	}

	public async ngOnInit(): Promise<void> {
		try {
			let status: ApiStatus | undefined = (await this._api.getStatus())?.body!;

			if(!status || (status && !(status?.online && status?.authenticate)))
				this.router.navigate([SITEMAP.maintenance.route]);

				if(status && !status.authenticate)
					this.router.navigate([SITEMAP.maintenance.route, { reason: 'authent' }]);
		}

		catch(err) {
			console.error(err);
			this.router.navigate([SITEMAP.maintenance.route]);
		}

		this.router.events.subscribe((event) => {
			if(event instanceof NavigationEnd)
				window.scrollTo(0, 0);
		});
	}

}
