<nav class="cb-admin-menu">

	<div class="cb-row">
		<div class="cb-menu-img" [routerLink]="[this.sitemap.admin.route]"></div>
	</div>

	<div class="cb-row">
		@for (link of this.links; track link) {
			<a
				class="cb-link"
				[class.active]="this.url === this.link.src"
				[matBadge]="(link.name === 'commandes' && this.orderRemains.length) ? this.orderRemains.length : ''"
				[routerLink]="[ this.link.src ]"
			>
				<mat-icon>{{ this.link.icon }}</mat-icon>
				<p>{{ this.link.name }}</p>
			</a>
		}
	</div>

	<div class="cb-row">
		<a class="cb-link" [routerLink]="[this.sitemap.main.route]">
			<mat-icon>arrow_back</mat-icon>
			<p>accueil du site</p>
		</a>
	</div>

</nav>