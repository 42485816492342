<div class="cb-account-details-reset-password-confirm">
	<div class="cb-account-details-reset-password-confirm-ctnr">
		<div class="cb-account-details-reset-password-confirm-algn">
			<div class="cb-title-ctnr">
				<h2 class="cb-title">Demande de modification de mot de passe</h2>
				<p class="cb-sub-title">
					Une demande de réinitilisation de mot de passe vous a été envoyé sur
					votre boite mail.
				</p>
			</div>

			<div class="cb-btn-algn">
				<app-cb-button
					class="cb-btn"
					[routerLink]="[this.sitemap.account.route]"
					color="primary"
					text="Retourner au profil"
				>
				</app-cb-button>
			</div>
		</div>
	</div>
</div>
