import { Component } from "@angular/core";
import {
	FormBuilder,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { RouterModule } from "@angular/router";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { CbButtonComponent } from "../../_commons/components/cb-button/cb-button.component";
import { HttpResponse } from "@angular/common/http";
import { ContactRequestDto } from "../../_commons/dtos/contact-request.dto";
import { ContactRequest } from "../../_commons/models/contact-request";
import { ApiContactRequestService } from "../../_commons/services/api/api-contact-request/api-contact-request.service";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

@Component({
	selector: "app-contact",
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		RouterModule,
		MatFormFieldModule,
		MatButtonModule,
		CbButtonComponent,
		MatIconModule,
		MatTooltipModule,
	],
	templateUrl: "./contact.component.html",
	styleUrl: "./contact.component.scss",
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContactComponent {

	public contactForm: FormGroup = this.fb.group({
		firstName: [null, Validators.required],
		lastName: [null, Validators.required],
		email: [null, [Validators.required, Validators.email]],
		phone: [null, [ Validators.required, Validators.pattern(/^([+][0-9]{1,3} |[0-9])[0-9] ([0-9]{2} ){3}[0-9]{2}$|^([0-9]{2}){5}$|^([+][0-9]{1,3}[0-9])([0-9]{2}){4}$/) ]],
		message: [null, [ Validators.required, Validators.maxLength(2048) ]],
	});

	public hasUnitNumber: boolean = false;
	public submitted: boolean = false;
	public success: boolean = false;
	public error: boolean = false;

	constructor(
		private fb: FormBuilder,
		private _contactRequest: ApiContactRequestService
	) {}

	public async onSubmit(): Promise<void> {
		if (this.contactForm.invalid)
			return;

		this.submitted = true;

		const contact: ContactRequestDto = this.contactForm.value;

		const resp: HttpResponse<ContactRequest> | undefined =
			await this._contactRequest.addContactRequest(contact);

		this.success = resp?.ok ? true : false;
		this.error = !resp?.ok ? true : false;
		this.submitted = false;

	}

}
