<form class="cb-sign-up-process" [formGroup]="this.signUpForm" (submit)="this.signUpProcess()">
	<div class="cb-sign-up-process-heading">
		<h3>inscription</h3>
		<p>veuillez entrer vos informations</p>
	</div>

	<div class="cb-sign-up-process-email-notif">
		<mat-icon>info</mat-icon>
		<p>
			nous avons envoyé un code à {{ this.email }}
			<a [routerLink]="[ this.sitemap.sign.route ]">Modifier</a>
		</p>
	</div>

	<div class="cb-sign-up-process-form-field">
		<label class="cb-label-radio">Civilité</label>
		<mat-radio-group formControlName="gender">
			<mat-radio-button [value]="1">M</mat-radio-button>
			<mat-radio-button [value]="2">Mme</mat-radio-button>
		</mat-radio-group>
	</div>

	<mat-form-field class="cb-sign-up-process-form-field" appearance="outline">
		<mat-label>prénom</mat-label>
		<input
			matInput
			name="firstName"
			type="text"
			placeholder="prénom"
			formControlName="firstName"
			required
		/>
		<mat-error>prénom requis</mat-error>
	</mat-form-field>

	<mat-form-field class="cb-sign-up-process-form-field" appearance="outline">
		<mat-label>nom</mat-label>
		<input
			matInput
			name="lastName"
			type="text"
			placeholder="nom"
			formControlName="lastName"
			required
		/>
		<mat-error>nom requis</mat-error>
	</mat-form-field>

	<mat-form-field class="cb-sign-up-process-form-field" appearance="outline">
		<mat-label>date de naissance</mat-label>
		<input
			matInput
			name="birthday"
			type="text"
			placeholder="DD/MM/YYYY"
			formControlName="birthday"
			[matDatepicker]="picker"
			required
		>
		<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
		<mat-datepicker #picker></mat-datepicker>

		@if (this.signUpForm.controls['birthday'].hasError('required')) {
			<mat-error>date de naissance requise</mat-error>
		}
		@if (this.signUpForm.controls['birthday'].hasError('pattern')) {
			<mat-error>date de naissance incorrecte</mat-error>
		}
	</mat-form-field>

	<mat-form-field class="cb-sign-up-process-form-field" appearance="outline">
		<mat-label>mot de passe</mat-label>
		<input
			matInput
			#password
			name="password"
			[type]="this.visibility ? 'text' : 'password'"
			placeholder="mot de passe"
			formControlName="password"
			required
		/>

		<button matSuffix mat-icon-button type="button" (click)="this.visibility = !this.visibility">
			<mat-icon>{{ this.visibility ? 'visibility' : 'visibility_off' }}</mat-icon>
		</button>

		@if (this.signUpForm.controls['password'].hasError('required')) {
			<mat-error>mot de passe requis</mat-error>
		}
		@if (this.signUpForm.controls['password'].hasError('minlength')) {
			<mat-error>mot de passe trop court</mat-error>
		}
		<mat-hint align="end">{{ password.value.length }}/8</mat-hint>
	</mat-form-field>

	<mat-form-field class="cb-sign-up-process-form-field" appearance="outline">
		<mat-label>code</mat-label>
		<input
			matInput
			#code
			name="code"
			type="text"
			placeholder="XXXXXX"
			formControlName="code"
			required
		/>
		@if(this.sendingCode) { <mat-icon matSuffix class="cb-spinning-icon">refresh</mat-icon> }
		@else { <mat-icon matSuffix>pin</mat-icon> }

		@if (this.signUpForm.controls['code'].hasError('required')) {
			<mat-error>code de confirmation requis</mat-error>
		}
		@if (this.signUpForm.controls['code'].hasError('pattern')) {
			<mat-error>code de confirmation au mauvais format</mat-error>
		}
	</mat-form-field>

	<div class="cb-sign-up-process-form-field">
		<mat-checkbox class="cb-sign-up-process-checkbox" formControlName="newsletter">je m'inscris à la newsletter pour recevoir des offres en avant première</mat-checkbox>
		<mat-checkbox class="cb-sign-up-process-checkbox" formControlName="cgv" required>j'ai lu et j'accepte les <a [href]="[this.sitemap.conditions.route]" target="_blank">conditions générales</a> *</mat-checkbox>
		@if (this.signUpForm.controls['cgv'].hasError('required')) {
			<mat-error>Les conditions générales n'ont pas été acceptées</mat-error>
		}
	</div>

	<app-cb-button
		class="cb-sign-up-process-submit"
		color="primary"
		text="inscription"
		type="submit"
		[disabled]="this.submitted || this.sendingCode"
		[loading]="this.submitted"
	></app-cb-button>
</form>