import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { Category } from '../../models/category';

import { ApiCategoryService } from '../api/api-category/api-category.service';
import { AccountService } from '../account/account.service';

@Injectable({
	providedIn: 'root'
})
export class CategoryService {

	private token: string = '';

	private approvalCategories: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>([]);
	public currentCategories: Observable<Category[]> = this.approvalCategories.asObservable();

	constructor(
		private _account: AccountService,
		private _category: ApiCategoryService
	) {
		this._account.currentUserToken.subscribe((token: string | null) => this.token = token || '');
		this.getCategories();
	}

	public async getCategories(): Promise<Category[]> {
		const categories: Category[] = (await this._category.getAll())?.body || [];
		this.approvalCategories.next(categories);

		return this.approvalCategories.getValue();
	}

	public async updateCategory(category: Category): Promise<Category | null> {
		try {
			const request: Category = (await this._category.update(this.token, category.id, { ...category }))?.body!;

			if(request) {
				const categories: Category[] = this.approvalCategories.getValue().map((c: Category) => c.id === category.id ? category: c);
				this.approvalCategories.next(categories);

				return request;
			}
		}

		catch (err) {}

		return null;
	}

	public async delete(category: Category): Promise<boolean> {
		try {
			const request: HttpResponse<void> | undefined = await this._category.delete(this.token, category.id);

			if(request && request.ok) {
				const categories: Category[] = this.approvalCategories.getValue().map((c: Category) => c.id === category.id ? category: c);
				this.approvalCategories.next(categories);

				return true;
			}
		}

		catch (err) {}

		return false;
	}
}
