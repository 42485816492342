import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ColissimoWeight } from '../../../../_commons/models/colissimo-weight';

import { AccountService } from '../../../../_commons/services/account/account.service';
import { ColissimoPriceService } from '../../../../_commons/services/colissimo-price/colissimo-price.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
	selector: 'app-admin-settings-colissimo-modal',
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		MatDialogModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatProgressSpinnerModule
	],
	templateUrl: './admin-settings-colissimo-modal.component.html',
	styleUrl: './admin-settings-colissimo-modal.component.scss'
})
export class AdminSettingsColissimoModalComponent {

	private token: string = '';

	public isLoading: boolean = false;

	public form: FormGroup = this.fb.group({
		priceHome: [],
		priceRelay: [],
		weight: []
	});

	constructor(
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<AdminSettingsColissimoModalComponent>,
		private sb: MatSnackBar,
		private _account: AccountService,
		private _colissimoWeight: ColissimoPriceService,
		@Inject(MAT_DIALOG_DATA) private data?: ColissimoWeight
	) {
		this._account.currentUserToken.subscribe((token: string | null) => this.token = token || '');

		if(data) {}
	}

	public async submit(): Promise<void> {
		if(this.form.invalid)
			return;

		this.isLoading = true;
		this.form.disable();

		

		this.isLoading = false;
		this.form.enable();
	}

}
