import { Component } from "@angular/core";

import { SignInComponent } from "../sign-in/sign-in.component";
import { SignUpComponent } from "../sign-up/sign-up.component";

@Component({
	selector: "app-sign-index",
	standalone: true,
	imports: [SignInComponent, SignUpComponent],
	templateUrl: "./sign-index.component.html",
	styleUrl: "./sign-index.component.scss",
})
export class SignIndexComponent {}
