import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { API_URL } from '../../constants';
import { HEADERS } from './headers';
import { ApiRoot } from '../../models/api-root';
import { ApiStatus } from '../../models/api-status';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	private API_URL: string = API_URL;
	private headers: HttpHeaders = HEADERS;

	constructor(private http: HttpClient) {}

	public async getRoot(): Promise<HttpResponse<ApiRoot> | undefined> {
		return this.http
			.get<ApiRoot>(`${this.API_URL}/`, { observe: 'response' })
			.toPromise();
	}

	public async getStatus(): Promise<HttpResponse<ApiStatus> | undefined> {
		return this.http
			.get<ApiStatus>(`${this.API_URL}/status`, { headers: this.headers, observe: 'response' })
			.toPromise();
	}

}
