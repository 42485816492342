import { SITEMAP } from "../_commons/sitemap";
import { Link } from "../_commons/models/link";

export const ADMIN_LINKS: Link[] = [
	{ id: 1, name: "accueil", src: SITEMAP.admin.route, icon: "home" },
	{ id: 2, name: "clients", src: SITEMAP.adminUsers.route, icon: "person" },
	{ id: 3, name: "commandes", src: SITEMAP.adminOrders.route, icon: "local_shipping" },
	{ id: 4, name: "catégories", src: SITEMAP.adminCategories.route, icon: "category" },
	{ id: 5, name: "produits", src: SITEMAP.adminProducts.route, icon: "inventory_2" },
	{ id: 6, name: "code promo", src: SITEMAP.adminPromoCodes.route, icon: "percent" },
	// { id: 7, name: "métriques", src: SITEMAP.adminMetrics.route, icon: "monitoring" },
	{ id: 8, name: "paramètres", src: SITEMAP.adminSettings.route, icon: "settings" },
	{ id: 9, name: "outils", src: SITEMAP.adminTools.route, icon: "construction" },
];