<div class="cb-order">
	<div class="cb-order-ctnr">
		<div class="cb-order-algn">
			<h2>Mes commandes</h2>

			<div class="cb-order-title-ctnr">
				@for(order of this.orders ; track order) {
					<app-order-item [order]="order"></app-order-item>
				}
			</div>
		</div>
	</div>
</div>
