import { HttpResponse } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormGroup, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";

import { SITEMAP, SiteMap } from "../../../../_commons/sitemap";
import { Address } from "../../../../_commons/models/address";
import { Profile } from "../../../../_commons/models/profile";
import { User } from "../../../../_commons/models/user";
import { ProfileDto } from "../../../../_commons/dtos/profile.dto";

import { AccountService } from "../../../../_commons/services/account/account.service";
import { ApiProfileService } from "../../../../_commons/services/api/api-profile/api-profile.service";

import { CbButtonComponent } from "../../../../_commons/components/cb-button/cb-button.component";
import { CbAddressSelectorComponent } from "../../../../_commons/components/cb-address-selector/cb-address-selector.component";

@Component({
	selector: "app-account-details-index",
	standalone: true,
	imports: [
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		CbButtonComponent,
		CbAddressSelectorComponent,
	],
	templateUrl: "./account-details-index.component.html",
	styleUrl: "../account-details.component.scss",
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AccountDetailsIndexComponent implements OnInit {

	public sitemap: SiteMap = SITEMAP;
	public user!: User;
	public address!: Address;
	public addresses?: Address[];

	public isProfileAccountFormEnable: boolean = false;

	public profileAccount: FormGroup = this.formBuilder.group({
		lastName: [null, [Validators.required]],
		firstName: [null, [Validators.required]],
		birthday: [null, [Validators.required]],
	});

	@ViewChildren("input") inputElements!: QueryList<ElementRef>;

	constructor(
		private _account: AccountService,
		private _profile: ApiProfileService,
		private formBuilder: FormBuilder
	) {}

	public async ngOnInit(): Promise<void> {
		this._account.currentUser.subscribe((user: User | null): void => {
			this.user = user!;

			if (this.user && this.user.profile) {
				this.address = this.user.profile.addresses?.find(
					(address: Address): boolean => address.isDefault
				)!;

				this.addresses = this.user.profile.addresses.filter(
					(address: Address): Address => address
				);
			}
		});

		this.profileAccount.disable();
	}

	public async saveChanges(): Promise<void> {
		const profileDto: ProfileDto = { ...this.profileAccount.value };

		try {
			const token: string | null = this._account.getUserToken();
			const updatingProfile: HttpResponse<Profile> | undefined =
				await this._profile.update(token!, this.user.profile.id, profileDto);

			if (updatingProfile?.ok) {
				this._account.updateAccount(this.user, token!);
				this.isProfileAccountFormEnable = false;
				this.profileAccount.disable();
			}
		} catch (err) {
			console.error(err);
		}
	}

	public enableInput(): void {
		this.isProfileAccountFormEnable = !this.isProfileAccountFormEnable;
		this.profileAccount.enable();
	}

	public cancelEdit(): void {
		this.isProfileAccountFormEnable = false;
		this._account.rebaseAccount();
		this.profileAccount.disable();
	}

}
