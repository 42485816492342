import { HttpHeaders, HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_URL } from "../../../constants";
import { ContactRequestDto } from "../../../dtos/contact-request.dto";
import { ContactRequest } from "../../../models/contact-request";
import { HEADERS } from "../headers";

@Injectable({
	providedIn: "root",
})
export class ApiContactRequestService {

	private API_URL: string = API_URL;
	private headers: HttpHeaders = HEADERS;
	private CONTACT_REQUEST_PATH: string = "contact-request";

	constructor(private http: HttpClient) {}

	public async getAll(): Promise<HttpResponse<ContactRequest[]> | undefined> {
		return await this.http
			.get<ContactRequest[]>(`${this.API_URL}/${this.CONTACT_REQUEST_PATH}/`, {
				headers: this.headers,
				observe: "response",
			})
			.toPromise();
	}

	public async getById(
		id: number
	): Promise<HttpResponse<ContactRequest> | undefined> {
		return await this.http
			.get<ContactRequest>(
				`${this.API_URL}/${this.CONTACT_REQUEST_PATH}/${id}`,
				{ headers: this.headers, observe: "response" }
			)
			.toPromise();
	}

	public async addContactRequest(
		contact: ContactRequestDto
	): Promise<HttpResponse<ContactRequest> | undefined> {
		return await this.http
			.post<ContactRequest>(
				`${this.API_URL}/${this.CONTACT_REQUEST_PATH}/`,
				contact,
				{ headers: this.headers, observe: "response" }
			)
			.toPromise();
	}
}
