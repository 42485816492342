<div class="cb-admin-products">

	<div class="cb-section-header">
		<h2 class="cb-title">Produits</h2>
	</div>

	@for (product of this.products; track product) {
		<mat-card>
			<mat-card-header>
				<mat-card-title>{{ product.name }}</mat-card-title>
			</mat-card-header>
			<div mat-card-image class="cb-admin-products-product-img" [style.background-image]="'url({0})' | replace : '{0}' : product.img"></div>
			<mat-card-actions align="end">
				<button
					mat-icon-button
					matTooltip="Modifier"
					(click)="this.openDialog(product)"
				>
					<mat-icon>edit</mat-icon>
				</button>

				<button
					class="cb-admin-products-delete-button"
					mat-icon-button
					matTooltip="Supprimer"
					(click)="this.delete(product)"
				>
					<mat-icon>delete</mat-icon>
				</button>
			</mat-card-actions>
		</mat-card>
	}

	@empty {
		@if (this.isLoading) {
			<div class="cb-admin-products-loading">
				<mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
			</div>
		}

		@else { Aucun produit n'a été trouvée }
	}

	<button
		mat-fab
		class="cb-admin-products-button"
		color="accent"
		matTooltip="Ajouter"
		(click)="this.openDialog()"
	>
		<mat-icon>add</mat-icon>
	</button>

</div>