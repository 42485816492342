<div class="cb-admin-users-item">

	<div class="cb-admin-users-item-left">
		@if (this.user.profile) {
			<h3>Profile</h3>
			<div>
				<p>{{ this.user.profile.firstName }} {{ this.user.profile.lastName }}</p>
				<p>Né(e) le {{ this.user.profile.birthday }}</p>
			</div>
		}
	</div>

	<div class="cb-admin-users-item-right">
		<mat-form-field appearance="outline">
			<mat-label>Statut</mat-label>
			<mat-select
				(valueChange)="this.updateUserRole($event);"
				[value]="this.user.role.id"
			>
				@for (role of this.roles; track role) {
					<mat-option [value]="role.id">{{ role.name === 'ADMIN' ? 'Administrateur' : 'Utilisateur' }}</mat-option>
				}
			</mat-select>
		</mat-form-field>
	</div>

</div>