import { Component } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { RouterModule } from "@angular/router";
import { MatInputModule } from "@angular/material/input";

import { SITEMAP, SiteMap } from "../../_commons/sitemap";

import { AccountService } from "../../_commons/services/account/account.service";

import { CbButtonComponent } from "../../_commons/components/cb-button/cb-button.component";

@Component({
	selector: "app-home",
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		MatButtonModule,
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		CbButtonComponent
	],
	templateUrl: "./home.component.html",
	styleUrl: "./home.component.scss",
})
export class HomeComponent {

	public sitemap: SiteMap = SITEMAP;

	constructor(
		private fb: FormBuilder,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer
	) {
		this.matIconRegistry.addSvgIcon(
			"instagram",
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"../../../assets/icons/socials/instagram.svg"
			)
		);
		this.matIconRegistry.addSvgIcon(
			"facebook",
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"../../../assets/icons/socials/facebook.svg"
			)
		);
	}

	public submitted: boolean = false;

	public newsLetterForm: FormGroup = this.fb.group({
		email: ["", [Validators.email]],
	});

	public subscribeToNewsLetter(): void {}

}
