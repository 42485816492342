import { Component, Input } from "@angular/core";
import { OrderedProduct } from "../../../../_commons/models/ordered-product";
import { Product } from "../../../../_commons/models/product";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { CbAddressSelectorComponent } from "../../../../_commons/components/cb-address-selector/cb-address-selector.component";
import { CbButtonComponent } from "../../../../_commons/components/cb-button/cb-button.component";
import { MatBadgeModule } from "@angular/material/badge";
import { CommonModule } from "@angular/common";
import { SITEMAP, SiteMap } from "../../../../_commons/sitemap";

@Component({
	selector: "app-order-item-product",
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatBadgeModule,
		CbButtonComponent,
		CbAddressSelectorComponent,
	],
	templateUrl: "./order-item-product.component.html",
	styleUrl: "./order-item-product.component.scss",
})
export class OrderItemProductComponent {
	@Input() public orderedProduct?: OrderedProduct;
	@Input() public product?: Product;
	@Input() public fixedSize!: string;
	@Input() public maxfixedSize!: string;
	@Input() public itemInfo!: Boolean;

	public sitemap: SiteMap = SITEMAP;

	constructor() {}

	public getProductWeight = (product: Product): number | null =>
		product.prices[0].weight || null;
}
