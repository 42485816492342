<header class="cb-header">
	<button mat-icon-button class="cb-menu-mobile-button" (click)="this.toggleMenuMobile()">
		<mat-icon>menu</mat-icon>
	</button>

	<div class="cb-logo" [routerLink]="[this.sitemap.main.route]">
		<img class="cb-logo-img" src="/assets/imgs/HERO/logo-hd.svg" alt="logo-hd.svg" [draggable]="false" />
	</div>

	<ul class="cb-links" [ngClass]="this.isMenuMobileOpen ? 'active' : ''">
		<button mat-icon-button class="cb-menu-mobile-button" (click)="this.toggleMenuMobile()">
			<mat-icon>close</mat-icon>
		</button>

		@for (link of this.links; track $index) {
			<li class="cb-links-link">

				@if (link.src) {
					<a class="cb-link" [routerLink]="[ link.src ]" (click)="this.toggleMenuMobile()">{{ link.name }}</a>
				}

				@if (link.href) {
					<a class="cb-link" [href]="[ link.href ]" (click)="this.toggleMenuMobile()">{{ link.name }}</a>
				}

			</li>
		}
	</ul>

	<div class="cb-buttons">
		@if(this.isSignedIn) {
			<button mat-icon-button class="cb-buttons-button" [routerLink]="[this.sitemap.account.route]">
				<mat-icon>person</mat-icon>
			</button>
		}

		@else {
			<a class="cb-buttons-button" [routerLink]="[this.sitemap.sign.route]">se connecter</a>
		}

		<button
			mat-icon-button
			class="cb-buttons-button"
			[matBadge]="this.cartItemsTotal"
			[matBadgeHidden]="!this.cartItemsTotal"
			matBadgeColor="accent"
			matBadgeOverlap="true"
			[routerLink]="[this.sitemap.cart.route]"
		>
			<mat-icon>shopping_cart</mat-icon>
		</button>
	</div>
</header>
