import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { MatExpansionModule } from '@angular/material/expansion';

interface Question {
	question: string;
	answer: string;
}

interface FAQSection {
	title: string;
	questions: Question[]
}

@Component({
	selector: "app-faq",
	standalone: true,
	imports: [
		CommonModule,
		MatExpansionModule
	],
	templateUrl: "./faq.component.html",
	styleUrl: "./faq.component.scss",
})
export class FaqComponent {

	public faqSections: FAQSection[] = [
		{
			title: "La boite à biscuits",
			questions: [
				{
					question: "La boite à biscuits, c'est quoi exactement ?",
					answer: "La boite contient un assortiment d'au moins 3 recettes de biscuits différents (60 à 80 pièces selon les recettes). De quoi recharger votre boite à biscuits pour le mois. Vous choisirez au 1er envoi de commander la boite en métal pour les conserver, à moins que vous en ayez déjà à la maison ! La recharge de biscuits est emballée dans des sachets 0% plastique, cellophane composée à 100% de cellulose de bois, compostable et made in France."
				},
				{
					question: "D'où proviennent les recettes ?",
					answer: "Les recettes ont chacune leur histoire. Elles proviennent de régions du Monde comme des livres de recettes de nos grands-mères. Christine les a adaptées au fil des années avec moins de sucre, des versions sans gluten, et a choisi de vous les proposer en 100% bio."
				},
				{
					question: "Puis-je tester une seule fois ?",
					answer: "Bien sûr ! il vous suffit d'aller dans la rubrique « la biscuiterie » pour choisir votre article, et commander de façon ponctuelle."
				},
				{
					question: "Est-ce que je peux composer moi-même l'assortiment ?",
					answer: "Pour l'abonnement, le principe de la boite est de vous réserver une surprise chaque mois. De nouvelles recettes ou des déclinaisons, adaptées à toute la famille et à chaque instant gourmand. Biscuits secs pour le café, beurrés pour le thé, faciles à emporter pour le goûter ou chocolatés… avec des déclinaisons pour souligner les saisons.Il est également possible de choisir son propre assortiment dans la boutique en ligne, en choisissant le format recharge sur le produit que vous souhaitez. Dans une boîte, vous pouvez choisir 3 produits différents. Si vous n'avez pas de boîte, vous pouvez également la commander ! "
				},
				{
					question: "Comment retrouver mes biscuits préférés ?",
					answer: "Les recettes les plus plébiscitées seront proposées individuellement dans la boutique « la biscuiterie »."
				},

				{
					question: "Comment retrouver la recette de Mamie Josette ?",
					answer: "N'hésitez pas à nous faire part de vos recettes oubliées au fond d'un tiroir. C'est avec grand plaisir que nous tenterons de les réaliser !"
				},

				{
					question: "Est-ce-que tous les ingrédients sont bio ?",
					answer: "CARREMENT BON s'applique tout au long du process à respecter l'environnement et les hommes. Le choix du 100% bio était sans concession, jusqu'au moindre ingrédient (extrait naturels ou huiles essentielles bios), et aucun conservateur ni additif ne rentre dans nos recettes."
				},

				{
					question: "Les biscuits sont-ils sans gluten ?",
					answer: "Vous avez la possibilité de choisir l'option « naturellement sans gluten » pour votre abonnement. Nous réalisons certaines de nos recettes « naturellement sans gluten ». Ces recettes toutes aussi gourmandes sont réalisées avec un mélange de farines naturellement sans gluten, présentant un intérêt nutritionnel certain grâce à la variété des protéines et minéraux qu'elles contiennent. Contrairement au « sans gluten » industriel, aucun ajout de gommes (guar, xanthane ou autre), mais l'utilisation de tégument de psyllium,  véritable allié santé du système digestif.Attention pour les allergiques, notre atelier utilisant aussi des farines de blé, nos produits peuvent contenir des traces de gluten. "
				},
			],
		},
		{
			title: "Le paiement",
			questions: [
				{
					question: "Quand mon compte sera-t-il débité ?",
					answer: "Votre compte sera débité à la commande pour les abonnements trimestriels, ou pour le 1er mois de l'abonnement mensuel, puis le 7 du mois pour les abonnements mensuels."
				},
				{
					question: "Le paiement est-il sécurisé ?",
					answer: "Le paiement est complètement sécurisé, via la plateforme de paiement « Sumup »"
				},
				{
					question: "Peut-on payer autrement que par carte bancaire ?",
					answer: "Il est impossible de payer autrement que par carte bancaire pour les abonnements mensuels. Par contre, pour les cartes cadeau ou les abonnements limités dans le temps, vous pouvez nous contacter (via le formulaire de contact) pour un paiement par virement ou chèque, la commande sera effective dès encaissement de votre paiement."
				},
				{
					question: "Comment profiter de mon code promo ?",
					answer: "Il vous suffit d'ajouter votre code promo dans la case dédiée, à la page « panier ». N'oubliez pas de valider."
				},
				{
					question: "Comment mettre à jour mes coordonnées bancaires ?",
					answer: "Il vous suffit pour cela d'aller dans « votre compte » et « mettre à jour » votre carte bancaire."
				},
			],
		},
		{
			title: "La livraison",
			questions: [
				{
					question: "Quels sont les différents modes de livraison ?",
					answer: "Les modes de livraisons proposés sont : - Colissimo pour les livraisons dans votre boite aux lettres. - Mondial Relay pour une livraison en point relais. - Des points de Pickup gratuits au niveau local."
				},
				{
					question: "A quelle date recevrai-je ma première boite ?",
					answer: "Les boites à biscuits sont livrées vers le 20 du mois. Pour votre 1er envoi, si votre paiement est effectif avant le 15, nous vous enverrons votre colis le 20. Sinon, votre envoi sera livré le mois suivant. "
				},
				{
					question: "Puis-je suivre la livraison ?",
					answer: "Pour Colissimo et Mondial Relay, si vous avez correctement rempli vos coordonnées lors de votre commande, vous aurez un suivi de livraison. Dans le cas du Pickup, nous vous préviendrons par mail et SMS de la date d'arrivée de votre colis. "
				},
				{
					question: "Comment modifier mon adresse de livraison ?",
					answer: "A tout moment, vous pouvez modifier votre adresse de livraison dans votre compte. Si vous le faites avant le 15 du mois, elle sera effective pour la livraison du mois."
				},
				{
					question: "Puis-je recevoir ou faire expédier ma boite à l'étranger ?",
					answer: "Bien sûr ! Nos solutions de livraison sont valables à différents tarifs dans plusieurs régions du monde. Essentiellement Europe et DOM TOM."
				},
			],
		},
		{
			title: "Les petits soucis",
			questions: [
				{
					question: "Ma boite à biscuits est arrivée en mauvais état, que dois-je faire ?",
					answer: "Prenez une photo de votre boite à biscuits et envoyez-la à notre adresse : contact@carrementbon.fr"
				},
				{
					question: "Je n'ai pas reçu ma boite à biscuits, que faire ?",
					answer: "A la fin du mois, si vous n'avez pas reçu votre boite à biscuits, vérifiez d'abord que votre colis n'a pas été réceptionné par un de vos proches ou voisins. S'il demeure introuvable, contactez-nous par mail afin que nous recherchions votre colis !"
				},
			],
		},
	];

}
