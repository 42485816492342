<mat-accordion class="cb-summary-cart">
	<mat-expansion-panel [expanded]="true">
		<mat-expansion-panel-header>
			<h2>mon panier</h2>
		</mat-expansion-panel-header>

		<div class="cb-summary-cart-content">
			<div class="cb-summary-cart-heading">
				<a [routerLink]="[this.sitemap.cart.route]">
					<strong>Modifier</strong>
				</a>
			</div>

			<div class="cb-summary-cart-calculs">
				<div class="cb-summary-cart-calculs-labels">
					<p>Sous-total</p>
					@if (this.promotion) { <p>Code Promo</p> }
					<p>Frais d'expédition</p>
					<p>Dont TVA ({{ this.tax * 100 }} %)</p>
					<p style="font-size: 1.25rem;">Total</p>
				</div>

				<div class="cb-summary-cart-calculs-values">
					<p>{{ this.subTotal | toPrice }} €</p>
					@if (this.promotion) { <p>-{{ this.promotion.promotion | toPrice }} €</p> }
					<p>{{ this.shipingCharge | toPrice }} €</p>
					<p>{{ this.taxSum() | toPrice }} €</p>
					<p style="font-size: 1.25rem;">{{ this.total | toPrice }} €</p>
				</div>
			</div>

			<div class="cb-summary-cart-products">
				
				@for (cartItem of this.cartItems; track $index) {
					<div class="cb-summary-cart-products-product">
						<div
							class="cb-summary-cart-products-product-img"
							[style.background-image]="'url({0})' | replace : '{0}' : cartItem.product.img"
							[matBadge]="cartItem.quantity"
							matBadgeOverlap="true"
							matBadgeColor="accent"
						></div>
						<div class="cb-summary-cart-products-product-info">
							<h3>{{ cartItem.product.name }}</h3>
							<p>{{ this.productSum(cartItem) | toPrice }} €</p>
						</div>
					</div>
				}

			</div>
		</div>
	</mat-expansion-panel>
</mat-accordion>
