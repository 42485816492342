<div class="cb-admin-promo-codes">

	<div class="cb-section-header">
		<h2 class="cb-title">Code Promos</h2>
	</div>

	<table mat-table [dataSource]="this.promotions">

		<ng-container matColumnDef="id">
			<th mat-header-cell *matHeaderCellDef>Id</th>
			<td mat-cell *matCellDef="let element">{{ element.id }}</td>
		</ng-container>

		<ng-container matColumnDef="code">
			<th mat-header-cell *matHeaderCellDef>Code</th>
			<td
				mat-cell
				*matCellDef="let element"
				[style]="element.uses >= element.usages ? { 'text-decoration': 'line-through' } : {}"
			>{{ element.code }}</td>
		</ng-container>

		<ng-container matColumnDef="promotion">
			<th mat-header-cell *matHeaderCellDef>Réduction</th>
			<td mat-cell *matCellDef="let element">{{ element.promotion }} €</td>
		</ng-container>

		<ng-container matColumnDef="expiration">
			<th mat-header-cell *matHeaderCellDef>Date d'expiration</th>
			<td mat-cell *matCellDef="let element">{{ (element.expiration | toDate).toLocaleString('fr') }}</td>
		</ng-container>

		<ng-container matColumnDef="usages">
			<th mat-header-cell *matHeaderCellDef>Nb. Usages</th>
			<td mat-cell *matCellDef="let element">{{ element.usages }}</td>
		</ng-container>

		<ng-container matColumnDef="uses">
			<th mat-header-cell *matHeaderCellDef>Nb. Utilisations</th>
			<td mat-cell *matCellDef="let element">{{ element.uses }}</td>
		</ng-container>

		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef>Actions</th>
			<td mat-cell *matCellDef="let element">
				<button
					mat-icon-button
					matTooltip="Modifier"
					(click)="this.openDialog(element)"
				>
					<mat-icon>edit</mat-icon>
				</button>

				<button
					mat-icon-button
					class="cb-admin-promo-codes-delete-button"
					matTooltip="Supprimer"
					(click)="this.delete(element)"
				>
					<mat-icon>delete</mat-icon>
				</button>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
	</table>

	@if (this.isLoading) {
		<div class="cb-admin-promo-codes-loading">
			<mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
		</div>
	}
	
	@else if (!this.promotions.length) { Aucun code promo n'a été trouvé }

	<button
		mat-fab
		class="cb-admin-promo-codes-button"
		color="accent"
		matTooltip="Ajouter"
		(click)="this.openDialog()"
	>
		<mat-icon>add</mat-icon>
	</button>

</div>