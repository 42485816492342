<div class="cb-cart">
	<div class="cb-cart-ctnr">
		<h2 class="cb-title">Panier</h2>

		<div class="cb-cart-summary">
			@if (this.cartItems.length > 0) {
				<div class="cb-cart-summary-items">
					@for ( orderedProduct of this.cartItems ; track orderedProduct) {
						<app-cart-item [orderedProduct]="orderedProduct"></app-cart-item>
					}
				</div>
			}

			@else {
				<div class="cb-cart-summary-text">votre panier est vide</div>
			}
		</div>

		<div class="cb-cart-payment">
			<div class="cb-row">
				<span class="cb-special-text">total</span>
				<div class="cb-highlight">{{ this.total | toPrice }} €</div>
			</div>
			<div class="cb-row">
				<p>les frais de port sont calculés lors du règlement.</p>
			</div>
		</div>

		<div class="cb-btn-algn">
			<app-cb-button
				class="cb-submit"
				color="primary"
				[text]="this.cartItems.length > 0 ? 'passer à la commande' : 'découvrir nos produits'"
				type="submit"
				[disabled]="false"
				[matTooltip]="false ? 'En raison de soucis techniques, la fonctionnalité de paiement est temporairement indisponible' : ''"
				[routerLink]="[this.cartItems.length > 0 ? this.sitemap.checkout.route : this.sitemap.shop.route]"
			></app-cb-button>
		</div>
	</div>
</div>
