import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { API_URL } from "../../../constants";
import { HEADERS } from "../headers";
import { Card } from "../../../models/card";
import { Success } from "../../../models/success";
import { SumupProcess } from "../../../models/sumup/sumup-process";
import { OrderContentDto } from "../../../dtos/order-content.dto";
import { CheckoutResponse } from "../../../models/checkout-response";

@Injectable({
	providedIn: "root",
})
export class ApiCheckoutService {

	private API_URL: string = API_URL;
	private SUMUP_URL: string = "https://api.sumup.com/v0.1";
	private headers: HttpHeaders = HEADERS;
	private CHECKOUT_PATH: string = "checkout";

	constructor(private http: HttpClient) {}

	public async createCheckout(
		token: string,
		orderContentDto: OrderContentDto
	): Promise<HttpResponse<CheckoutResponse> | undefined> {
		return this.http
			.post<CheckoutResponse>(
				`${this.API_URL}/${this.CHECKOUT_PATH}/`,
				orderContentDto,
				{
					headers: this.headers
						.append("token", token)
						.append('origin', window.location.origin),
					observe: "response"
				}
			)
			.toPromise();
	}

	public async proceed(
		idCheckout: string,
		card: Card
	): Promise<HttpResponse<SumupProcess> | undefined> {
		return this.http
			.put<SumupProcess>(
				`${this.SUMUP_URL}/checkouts/${idCheckout}`,
				{ payment_type: "card", card },
				{ observe: "response" }
			)
			.toPromise();
	}

	public async check(
		token: string,
		checkoutId: string
	): Promise<HttpResponse<Success> | undefined> {
		return this.http
			.get<Success>(`${this.API_URL}/${this.CHECKOUT_PATH}/${checkoutId}`, {
				headers: this.headers.append("token", token),
				observe: "response",
			})
			.toPromise();
	}
}
