import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Address } from '../../models/address';
import { Order } from '../../models/order';

import { ApiOrderService } from '../api/api-order/api-order.service';
import { AccountService } from '../account/account.service';

@Injectable({
	providedIn: 'root'
})
export class OrderService {

	private token: string = '';

	private approvalOrders: BehaviorSubject<Order[]> = new BehaviorSubject<Order[]>([]);
	public currentOrders: Observable<Order[]> = this.approvalOrders.asObservable();

	constructor(
		private _order: ApiOrderService,
		private _account: AccountService,
	) {
		this._account.currentUserToken.subscribe((token: string | null) => this.token = token || '');
		this.getOrders();
	}

	public async getOrders(): Promise<Order[]> {
		const orders: Order[] = (await this._order.getAll(this.token))?.body || [];
		this.approvalOrders.next(orders);

		return this.approvalOrders.getValue();
	}

	public async updateOrder(order: Order): Promise<Order | null> {
		try {
			const request: Order = (await this._order.update(this.token, order.id, {
				...order,
				billedAddress: <Address>JSON.parse(order.billedAddress),
				deliveredAddress: <Address>JSON.parse(order.deliveredAddress)
			}))?.body!;

			if(request) {
				const orders: Order[] = this.approvalOrders.getValue().map((o: Order) => o.id === order.id ? order: o);
				this.approvalOrders.next(orders);

				return request;
			}
		}

		catch (err) {}

		return null;
	}

}
