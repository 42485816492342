<form class="cb-sign-in" [formGroup]="this.signInForm" (submit)="this.signIn()">
	<div class="cb-sign-in-heading">
		<h3>déjà membre</h3>
		<p>connectez-vous à votre espace personnel</p>
	</div>

	<mat-form-field class="cb-sign-in-form-field" appearance="outline">
		<mat-label>adresse e-mail</mat-label>
		<input
			matInput
			name="email"
			type="email"
			placeholder="adresse e-mail"
			formControlName="email"
		/>
		<mat-icon matSuffix>mail</mat-icon>
		@if (this.signInForm.controls['email'].hasError('required')) {
			<mat-error>adresse e-mail requise</mat-error>
		}
		@if (this.signInForm.controls['email'].hasError('email')) {
			<mat-error>adresse e-mail incorrecte</mat-error>
		}
	</mat-form-field>

	<mat-form-field class="cb-sign-in-form-field" appearance="outline">
		<mat-label>mot de passe</mat-label>
		<input
			matInput
			name="password"
			type="password"
			placeholder="mot de passe"
			formControlName="password"
		/>
		<mat-icon matSuffix>password</mat-icon>
		@if (this.signInForm.controls['password'].hasError('required')) {
			<mat-error>mot de passe requis</mat-error>
		}
		@if (this.signInForm.controls['password'].hasError('minlength')) {
			<mat-error>mot de passe trop court</mat-error>
		}
	</mat-form-field>

	<p class="cb-sign-in-forgot-password">
		<a [routerLink]="[this.sitemap.signForgotPassword.route]">mot de passe oublié ?</a>
	</p>

	<app-cb-button
		class="cb-sign-in-submit"
		color="primary"
		text="connexion"
		type="submit"
		[disabled]="this.submitted"
		[loading]="this.submitted"
	></app-cb-button>
</form>