import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldAppearance, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

import { PaymentForm } from '../../models/payment-form';

@Component({
	selector: 'app-cb-payment-form',
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule
	],
	templateUrl: './cb-payment-form.component.html',
	styleUrl: './cb-payment-form.component.scss'
})
export class CbPaymentFormComponent {

	@Input() public appearance: MatFormFieldAppearance = 'outline';

	@Output() public invalid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() public paymentEvent: EventEmitter<PaymentForm> = new EventEmitter<PaymentForm>();

	@Input() public payment: PaymentForm = {
		name: '',
		number: '',
		expiration: '',
		cvv: ''
	};

	public paymentForm: FormGroup = this.fb.group({
		name: [this.payment.name, [ Validators.required, Validators.pattern(/^([a-z]|[A-Z]|[áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ-]| )*$/) ]],
		number: [this.payment.number, [ Validators.required, Validators.pattern(/^([0-9]{4} ){3}[0-9]{4}$|^([0-9]{4} ){4}[0-9]{3}$/) ]],
		expiration: [this.payment.expiration, [ Validators.required, Validators.pattern(/^([0-1][0-2]|[0-0][0-9])\/[0-9]{2}$/) ]],
		cvv: [this.payment.cvv, [ Validators.required, Validators.pattern(/^[0-9]{3}$|^[0-9]{4}$/) ]]
	});

	constructor(private fb: FormBuilder) {}

	public updateNumber = (): string => this.payment.number = this.payment.number.match(/^[0-9]{5}$|^[0-9]{4} [0-9]{5}$|^([0-9]{4} ){2}[0-9]{5}$|^([0-9]{4} ){3}[0-9]{5}$/)
		? `${this.payment.number.substring(0, this.payment.number.length-1)} ${this.payment.number.slice(-1)}`:
		this.payment.number;

	public updateDate = (): string => this.payment.expiration += this.payment.expiration.match(/^[0-9]{2}$/) ? '/' : '';

	public update(): void {

		this.invalid.emit(this.paymentForm.invalid);
		this.paymentEvent.emit(this.payment);

	}

}
