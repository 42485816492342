<mat-dialog-content class="cb-mat-dialog">
	<h2>Contenu de la commande</h2>

	<table id="tableId" mat-table [dataSource]="this.dataSource" class="cb-mat-table">
		@for(column of this.displayedColumns; track column) {
		<tr [matColumnDef]="column">
			<th mat-header-cell *matHeaderCellDef>{{ column }}</th>
			<td mat-cell *matCellDef="let element">
				{{ column === "produit" ? element.product.name : "" }}
				{{ column === "prix" ? (element.price.value | toPrice) + " €" : "" }}
				{{ column === "quantité" ? element.quantity : "" }}
				{{ column === "total" ? (element.quantity * element.price.value | toPrice) + " €" : "" }}
				{{ element[column] }}
			</td>
		</tr>
		}

		<tr mat-header-row *matHeaderRowDef="this.displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: this.displayedColumns"></tr>
	</table>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-button mat-dialog-close>Fermer</button>
	<button
		color="accent"
		mat-flat-button
		cdkFocusInitial
		[mat-dialog-close]="true"
		(click)="this.exportToExcel()"
	>
		<mat-icon>download</mat-icon>Exporter en Excel
	</button>
	<button
		color="accent"
		mat-flat-button
		cdkFocusInitial
		[mat-dialog-close]="true"
		(click)="this.exportToPDF()"
	>
		<mat-icon>picture_as_pdf</mat-icon>Exporter en PDF
	</button>
</mat-dialog-actions>

<!--  prix hors taxe 1 seul produit = Prix TTC - (Prix TTC * 0.055)  -->
<!--  prix hors taxe plusieurs produits = (Prix TTC - (Prix TTC * 0.055)) * quantity -->
