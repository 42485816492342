<mat-dialog-content class="cb-category-modal">

	@if (this.submitted) {
		<div class="cb-category-modal-loading">
			<mat-progress-spinner
				diameter="48"
				mode="indeterminate"
			></mat-progress-spinner>
		</div>
	}

	<form class="cb-category-form" [formGroup]="this.categoryForm" (submit)="this.submit()">
		<div class="cb-category-form-heading">
			<h2>@if (this.isEditMode) { Modifier la } @else { Ajouter une } catégorie</h2>
		</div>

		<div class="cb-category-form-row">
			<mat-form-field class="cb-category-form-field" appearance="outline">
				<mat-label>nom</mat-label>
				<input
					matInput
					#name
					name="name"
					type="text"
					placeholder="nom"
					formControlName="name"
					required
					(ngModelChange)="this.updateSlug($event)"
				/>
				<mat-error>nom requis</mat-error>
			</mat-form-field>
		</div>

		<div class="cb-category-form-row">
			<mat-form-field class="cb-category-form-field" appearance="outline">
				<mat-label>slug</mat-label>
				<input
					matInput
					#slug
					name="slug"
					type="text"
					placeholder="slug"
					formControlName="slug"
					required
					[(ngModel)]="this.slugString"
				/>
				@if (this.categoryForm.controls['slug'].hasError('required')) {
					<mat-error>slug requis</mat-error>
				}

				@if (this.categoryForm.controls['slug'].hasError('pattern')) {
					<mat-error>slug incorrect</mat-error>
				}
			</mat-form-field>
		</div>

		<div class="cb-category-form-buttons">
			<button
				mat-flat-button
				color="accent"
				type="submit"
				[disabled]="this.submitted || this.categoryForm.invalid"
			>
				@if (this.isEditMode) { Modifier } @else { Ajouter }
			</button>

			<button mat-flat-button mat-dialog-close>
				Annuler
			</button>
		</div>
	</form>

</mat-dialog-content>