import { Component } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

import { Success } from '../../_commons/models/success';
import { MailDto } from '../../_commons/dtos/mail.dto';

import { ApiAuthService } from '../../_commons/services/api/api-auth/api-auth.service';

import { CbButtonComponent } from '../../_commons/components/cb-button/cb-button.component';
import { CbModalComponent } from '../../_commons/components/cb-modal/cb-modal.component';
import { SITEMAP, SiteMap } from '../../_commons/sitemap';

@Component({
	selector: 'app-forgot-password',
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		CbButtonComponent
	],
	templateUrl: './forgot-password.component.html',
	styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {

	public sitemap: SiteMap = SITEMAP;

	public success: boolean = false;
	public submitted: boolean = false;

	public resetPasswordForm: FormGroup = this.fb.group({
		email: ['', [ Validators.required, Validators.email ]],
	});

	constructor(
		public dialog: MatDialog,
		private fb: FormBuilder,
		private _auth: ApiAuthService
	) {}

	public async resetPassword(): Promise<void> {
		if(this.resetPasswordForm.invalid)
			return;

		this.submitted = true;
		this.success = (await this._auth.resetPassword(<MailDto>this.resetPasswordForm.value))?.body?.success!;

		this.dialog.open(CbModalComponent, {
			data: {
				title: 'Réussite',
				message: 'Si un compte existe, le courriel de réinitialisation a été envoyé',
				buttonText: 'fermer'
			}
		});

		this.submitted = false;
	}
}
