import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
	selector: 'app-admin-settings-mondial-relay',
	standalone: true,
	imports: [
		MatTabsModule
	],
	templateUrl: './admin-settings-mondial-relay.component.html',
	styleUrl: './admin-settings-mondial-relay.component.scss'
})
export class AdminSettingsMondialRelayComponent {

}
