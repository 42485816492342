import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { AdminToolsGenerateColissimoModalComponent } from './admin-tools-generate-colissimo-modal/admin-tools-generate-colissimo-modal.component';

@Component({
	selector: 'app-admin-tools',
	standalone: true,
	imports: [
		MatButtonModule,
		MatIconModule,
	],
	templateUrl: './admin-tools.component.html',
	styleUrl: './admin-tools.component.scss'
})
export class AdminToolsComponent {

	constructor(
		private dialog: MatDialog,
	) {}

	public openColissimoModal(): void {
		this.dialog.open(AdminToolsGenerateColissimoModalComponent);
	}

}
