import { Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { SITEMAP, SiteMap } from '../../../_commons/sitemap';
import { Category } from '../../../_commons/models/category';

import { ReplacePipe } from "../../../_commons/pipes/replace/replace.pipe";

@Component({
	selector: 'app-categories',
	standalone: true,
	templateUrl: './categories.component.html',
	styleUrl: './categories.component.scss',
	imports: [RouterModule, ReplacePipe]
})
export class CategoriesComponent {

	@Input() public categories: Category[] = [];

	public sitemap: SiteMap = SITEMAP;

	constructor(public router: Router) {}

}
