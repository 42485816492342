import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { API_URL } from '../../../constants';
import { HEADERS } from '../headers';
import { ColissimoWeight } from '../../../models/colissimo-weight';
import { ColissimoWeightDto } from '../../../dtos/colissimo-weight.dto';

@Injectable({
	providedIn: 'root'
})
export class ApiColissimoPriceService {

	private API_URL: string = API_URL;
	private headers: HttpHeaders = HEADERS;
	private COLISSIMO_WEIGHT_PATH: string = "colissimo-weight";

	constructor(private http: HttpClient) {}

	public async getAll(): Promise<HttpResponse<ColissimoWeight[]> | undefined> {
		return this.http
			.get<ColissimoWeight[]>(`${this.API_URL}/${this.COLISSIMO_WEIGHT_PATH}/`, {
				headers: this.headers,
				observe: "response",
			})
			.toPromise();
	}

	public async getById(id: number): Promise<HttpResponse<ColissimoWeight> | undefined> {
		return this.http
			.get<ColissimoWeight>(`${this.API_URL}/${this.COLISSIMO_WEIGHT_PATH}/${id}`, {
				headers: this.headers,
				observe: "response",
			})
			.toPromise();
	}

	public async add(token: string, dto: ColissimoWeightDto): Promise<HttpResponse<ColissimoWeight> | undefined> {
		return this.http
			.post<ColissimoWeight>(`${this.API_URL}/${this.COLISSIMO_WEIGHT_PATH}/`, dto, {
				headers: this.headers.append('token', token),
				observe: "response"
			})
			.toPromise();
	}

	public async update(token: string, id: number, dto: ColissimoWeightDto): Promise<HttpResponse<ColissimoWeight> | undefined> {
		return this.http
			.put<ColissimoWeight>(`${this.API_URL}/${this.COLISSIMO_WEIGHT_PATH}/${id}`, dto, {
				headers: this.headers.append('token', token),
				observe: "response"
			})
			.toPromise();
	}

	public async delete(token: string, id: number): Promise<HttpResponse<void> | undefined> {
		return this.http
			.delete<void>(`${this.API_URL}/${this.COLISSIMO_WEIGHT_PATH}/${id}`, {
				headers: this.headers.append('token', token),
				observe: "response"
			})
			.toPromise();
	}
}
