import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { SITEMAP } from '../../sitemap';
import { OrderedProduct } from '../../models/ordered-product';

import { CartService } from '../../services/cart/cart.service';

export const isCartEmptyGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {

	const router: Router = inject(Router);
	const _cart: CartService = inject(CartService);

	const cartItems: OrderedProduct[] = _cart.getCartItems();

	if(cartItems.length)
		return true;

	router.navigate([ SITEMAP.main.route ]);
	return false;

};
