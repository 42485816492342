<mat-form-group class="cb-payment-form" [formGroup]="this.paymentForm">
	<mat-form-field class="cb-form-field" [appearance]="this.appearance">
		<mat-label class="cb-form-label">nom sur la carte</mat-label>

		<input
			class="cb-form-input"
			matInput
			#name
			type="text"
			placeholder="Prénom Nom"
			formControlName="name"
			[(ngModel)]="this.payment.name"
			(ngModelChange)="this.update()"
			required
		/>

		<mat-icon matSuffix>person</mat-icon>

		@if (this.paymentForm.controls['name'].hasError('required')) {
			<mat-error class="cb-form-error">nom de carte requis</mat-error>
		}

		@if (this.paymentForm.controls['name'].hasError('pattern')) {
			<mat-error class="cb-form-error">nom de carte invalide</mat-error>
		}
	</mat-form-field>

	<mat-form-field class="cb-form-field" [appearance]="this.appearance">
		<mat-label class="cb-form-label">numéro de carte</mat-label>

		<input
			class="cb-form-input"
			matInput
			#cardNumber
			type="text"
			placeholder="0000 0000 0000 0000 (000)"
			formControlName="number"
			[(ngModel)]="this.payment.number"
			(ngModelChange)="this.updateNumber(); this.update()"
			maxlength="23"
			required
		/>

		<mat-icon matSuffix>credit_card</mat-icon>

		@if (this.paymentForm.controls['number'].hasError('required')) {
			<mat-error class="cb-form-error">numéro de carte requis</mat-error>
		}

		@if (this.paymentForm.controls['number'].hasError('pattern')) {
			<mat-error class="cb-form-error">numéro de carte invalide</mat-error>
		}
	</mat-form-field>

	<mat-form-field class="cb-form-field" [appearance]="this.appearance">
		<mat-label class="cb-form-label">date d'expiration</mat-label>

		<input
			class="cb-form-input"
			matInput
			#expiration
			type="text"
			placeholder="00/00"
			formControlName="expiration"
			[(ngModel)]="this.payment.expiration"
			(ngModelChange)="this.updateDate(); this.update()"
			maxlength="5"
			required
		/>

		<mat-icon matSuffix>calendar_month</mat-icon>

		@if (this.paymentForm.controls['expiration'].hasError('required')) {
			<mat-error class="cb-form-error">date d'expiration requise</mat-error>
		}

		@if (this.paymentForm.controls['expiration'].hasError('pattern')) {
			<mat-error class="cb-form-error">date d'expiration invalide</mat-error>
		}
	</mat-form-field>

	<mat-form-field class="cb-form-field" [appearance]="this.appearance">
		<mat-label class="cb-form-label">code de sécurité</mat-label>

		<input
			class="cb-form-input"
			matInput
			#cvc
			type="text"
			placeholder="000(0)"
			formControlName="cvv"
			[(ngModel)]="this.payment.cvv"
			(ngModelChange)="this.update()"
			maxlength="4"
			required
		/>

		<mat-icon matSuffix>lock</mat-icon>

		@if (this.paymentForm.controls['cvv'].hasError('required')) {
			<mat-error class="cb-form-error">code de sécurité requis</mat-error>
		}

		@if (this.paymentForm.controls['cvv'].hasError('pattern')) {
			<mat-error class="cb-form-error">code de sécurité invalide</mat-error>
		}
	</mat-form-field>
</mat-form-group>