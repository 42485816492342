<mat-dialog-content class="cb-admin-settings-colissimo-modal">

	@if (this.isLoading) {
		<div class="cb-admin-settings-colissimo-modal-loading">
			<mat-progress-spinner
				diameter="48"
				mode="indeterminate"
			></mat-progress-spinner>
		</div>
	}

	<div class="cb-admin-settings-colissimo-heading">
		<h2>Ajouter un prix colissimo</h2>
	</div>

	<form class="cb-admin-promo-codes-form" [formGroup]="this.form" (submit)="this.submit()">
		<div class="cb-admin-promo-codes-form-row">
			<mat-form-field class="cb-admin-promo-codes-form-field" appearance="outline">
				<mat-label>poids (g)</mat-label>
				<input
					matInput
					#weight
					name="weight"
					type="text"
					placeholder="poids (g)"
					formControlName="weight"
					required
				/>
				<mat-icon matSuffix>monitor_weight</mat-icon>
				<mat-error>poids requis</mat-error>
			</mat-form-field>
		</div>

		<div class="cb-admin-promo-codes-form-row">
			<mat-form-field class="cb-admin-promo-codes-form-field" appearance="outline">
				<mat-label>prix domicile</mat-label>
				<input
					matInput
					#priceHome
					[min]="0"
					name="priceHome"
					type="number"
					placeholder="prix domicile (€)"
					formControlName="priceHome"
					required
				/>
				<mat-icon matSuffix>euro</mat-icon>

				@if(this.form.controls['priceHome'].hasError('required')) {
					<mat-error>prix domicile requis</mat-error>
				}
				@if(this.form.controls['priceHome'].hasError('pattern')) {
					<mat-error>doit être un nombre</mat-error>
				}
				@if(this.form.controls['priceHome'].hasError('min')) {
					<mat-error>doit être supérieur à 0</mat-error>
				}
			</mat-form-field>
		</div>

		<div class="cb-admin-promo-codes-form-row">
			<mat-form-field class="cb-admin-promo-codes-form-field" appearance="outline">
				<mat-label>prix relais</mat-label>
				<input
					matInput
					#priceRelay
					[min]="0"
					name="priceRelay"
					type="number"
					placeholder="prix relais (€)"
					formControlName="priceRelay"
					required
				/>
				<mat-icon matSuffix>euro</mat-icon>

				@if(this.form.controls['priceRelay'].hasError('required')) {
					<mat-error>prix relais requis</mat-error>
				}
				@if(this.form.controls['priceRelay'].hasError('pattern')) {
					<mat-error>doit être un nombre</mat-error>
				}
				@if(this.form.controls['priceRelay'].hasError('min')) {
					<mat-error>doit être supérieur à 0</mat-error>
				}
			</mat-form-field>
		</div>
	</form>

</mat-dialog-content>