import { Component, OnInit } from "@angular/core";
import { RouterModule } from "@angular/router";

import { AccountService } from "../_commons/services/account/account.service";

import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";

@Component({
	selector: "app-main",
	standalone: true,
	imports: [
		RouterModule,
		HeaderComponent, 
		FooterComponent
	],
	templateUrl: "./main.component.html",
	styleUrl: "./main.component.scss",
})
export class MainComponent implements OnInit {

	constructor(private _account: AccountService) {}

	public async ngOnInit(): Promise<void> {
		await this._account.checkAuth();
	}

}
