import { Injectable } from "@angular/core";
import { Address } from "../../../models/address";
import { AddressDto } from "../../../dtos/address.dto";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { HEADERS } from "../headers";
import { API_URL } from "../../../constants";
@Injectable({
	providedIn: "root",
})
export class ApiAddressService {

	private API_URL: string = API_URL;
	private headers: HttpHeaders = HEADERS;
	private ADDRESS_PATH: string = "address";

	constructor(private http: HttpClient) {}

	public async getShopAddresses(): Promise<Address[] | undefined> {
		return this.http
			.get<Address[]>(`${this.API_URL}/${this.ADDRESS_PATH}/shop-addresses`)
			.toPromise();
	}

	public async getAll(token: string): Promise<Address[] | undefined> {
		return this.http
			.get<Address[]>(`${this.API_URL}/${this.ADDRESS_PATH}/`, {
				headers: this.headers.append("token", token),
			})
			.toPromise();
	}

	public async getById(
		token: string,
		id: number
	): Promise<Address | undefined> {
		return this.http
			.get<Address>(`${this.API_URL}/${this.ADDRESS_PATH}/${id}`, {
				headers: this.headers.append("token", token),
			})
			.toPromise();
	}

	public async add(
		token: string,
		addressDto: AddressDto
	): Promise<Address | undefined> {
		return this.http
			.post<Address>(`${this.API_URL}/${this.ADDRESS_PATH}/`, addressDto, {
				headers: this.headers.append("token", token),
			})
			.toPromise();
	}

	public async update(
		token: string,
		id: number,
		addressDto: AddressDto
	): Promise<Address | undefined> {
		return this.http
			.put<Address>(`${this.API_URL}/${this.ADDRESS_PATH}/${id}`, addressDto, {
				headers: this.headers.append("token", token),
			})
			.toPromise();
	}

	public async delete(token: string, id: number): Promise<void> {
		return this.http
			.delete<void>(`${this.API_URL}/${this.ADDRESS_PATH}/${id}`, {
				headers: this.headers.append("token", token),
			})
			.toPromise();
	}
}
