import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { CbButtonComponent } from "../cb-button/cb-button.component";

@Component({
	selector: "app-cb-modal",
	standalone: true,
	imports: [MatDialogModule, CbButtonComponent],
	templateUrl: "./cb-modal.component.html",
	styleUrl: "./cb-modal.component.scss",
})
export class CbModalComponent {

	public title: string | null = null;
	public message: string | null = null;
	public buttonText: string | null = null;

	constructor(
		public dialogRef: MatDialogRef<CbModalComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any
	) {
		this.title = data?.title || null;
		this.message = data?.message || null;
		this.buttonText = data?.buttonText || null;
	}

}
