import * as uuid from 'uuid';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';

import { COUNTRIES } from '../../../_commons/country';
import { Country } from '../../../_commons/models/country';

import { ApiDeliveryService } from '../../../_commons/services/api/api-delivery/api-delivery.service';
import { AccountService } from '../../../_commons/services/account/account.service';

@Component({
	selector: 'app-admin-tools-generate-colissimo-modal',
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatDialogModule,
		MatFormFieldModule,
		MatInputModule,
		MatProgressSpinnerModule,
		MatSelectModule,
	],
	templateUrl: './admin-tools-generate-colissimo-modal.component.html',
	styleUrl: './admin-tools-generate-colissimo-modal.component.scss'
})
export class AdminToolsGenerateColissimoModalComponent {

	private token: string = '';

	public countries: Country[] = COUNTRIES;
	public isLoading: boolean = false;

	public labelForm: FormGroup = this.fb.group({
		weight: [null, Validators.required],
	});

	public addressForm: FormGroup = this.fb.group({
		firstName: [null, Validators.required],
		lastName: [null, Validators.required],
		phone: [null, Validators.required],
		street: [null, Validators.required],
		city: [null, Validators.required],
		zip: [null, Validators.required],
		country: [this.countries[0].value, Validators.required],
	});

	constructor(
		private dialogRef: MatDialogRef<AdminToolsGenerateColissimoModalComponent>,
		private fb: FormBuilder,
		private _delivery: ApiDeliveryService,
		private _account: AccountService,
	) {
		this._account.currentUserToken.subscribe((token: string | null) => this.token = token || '');
	}

	public isInvalidForms = (): boolean => this.labelForm.invalid || this.addressForm.invalid;

	public async submit(): Promise<void> {
		this.isLoading = true;

		const blob: Blob = (await this._delivery.generateLabel(this.token, {
			reference: uuid.v4(),
			...this.labelForm.value,
			deliveredAddress: { ...this.addressForm.value }
		}))?.body!;

		const blobURL: string = window.URL.createObjectURL(blob);
		const downloadLink: HTMLAnchorElement = document.createElement('a');

		downloadLink.href = blobURL;
		downloadLink.setAttribute('download', `COLISSIMO_CARREMENT_BON_SAMPLE.pdf`);
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
		window.URL.revokeObjectURL(blobURL);

		this.isLoading = false;
		this.dialogRef.close();
	}

}
