<div class="cb-contact">
	<div class="cb-contact-ctnr">
		<h2 class="cb-title">Contact</h2>

		<div class="cb-form-ctnr">
			<form class="cb-form" [formGroup]="this.contactForm" novalidate (ngSubmit)="this.onSubmit()">
				<div class="cb-short-field-container">
					<div class="cb-form-short-field">
						<mat-form-field class="cb-form-field cb-form-input-left" appearance="outline">
							<mat-label class="cb-label">prénom</mat-label>
							<input
								class="cb-form-input"
								matInput
								#firstName
								placeholder="prénom"
								formControlName="firstName"
							/>

							<mat-error class="cb-error">prénom requis</mat-error>
						</mat-form-field>
					</div>

					<div class="cb-form-short-field">
						<mat-form-field class="cb-form-field cb-form-input-right" appearance="outline">
							<mat-label class="cb-label">nom</mat-label>
							<input
								class="cb-form-input"
								matInput
								#lastName
								placeholder="nom"
								formControlName="lastName"
							/>

							<mat-error class="cb-error">nom requis</mat-error>
						</mat-form-field>
					</div>

					<div class="cb-form-short-field">
						<mat-form-field class="cb-form-field cb-form-input-left" appearance="outline">
							<mat-label class="cb-label">adresse e-mail</mat-label>
							<input
								class="cb-form-input"
								matInput
								#email
								placeholder="adresse e-mail"
								formControlName="email"
							/>

							@if(this.contactForm.controls['email'].hasError('required')) {
								<mat-error class="cb-error">adresse e-mail requise</mat-error>
							}

							@if(this.contactForm.controls['email'].hasError('email')) {
								<mat-error class="cb-error">adresse e-mail incorrecte</mat-error>
							}
						</mat-form-field>
					</div>

					<div class="cb-form-short-field">
						<mat-form-field class="cb-form-field cb-form-input-right" appearance="outline">
							<mat-label class="cb-label">téléphone</mat-label>
							<input
								class="cb-form-input"
								matInput type="tel"
								#phone
								placeholder="téléphone"
								formControlName="phone"
							/>

							@if(this.contactForm.controls['phone'].hasError('required')) {
								<mat-error class="cb-error">téléphone requis</mat-error>
							}

							@if(this.contactForm.controls['phone'].hasError('pattern')) {
								<mat-error class="cb-error">téléphone incorrect</mat-error>
							}
						</mat-form-field>
					</div>
				</div>

				<mat-form-field class="cb-form-long-field" appearance="outline">
					<mat-label class="cb-label">message</mat-label>
					<textarea 
						matInput 
						#message
						placeholder="message" 
						formControlName="message"
					></textarea>

					<mat-hint align="end">{{ this.message.value.length }}/2048</mat-hint>

					@if (this.contactForm.controls['message'].hasError('required')) {
						<mat-error class="cb-error">message requis</mat-error>
					}

					@if (this.contactForm.controls['message'].hasError('maxlength')) {
						<mat-error class="cb-error">message trop long</mat-error>
					}
				</mat-form-field>

				<div class="cb-btn-algn">
					<app-cb-button
						mat-flat-button
						color="primary"
						text="envoyer"
						type="submit"
						[loading]="this.submitted"
						[disabled]="this.contactForm.invalid || this.submitted || this.success"
					></app-cb-button>
				</div>

				@if(this.success) {
					<mat-info class="cb-success">Le formulaire de contact à bien été envoyé !</mat-info>
				}

				@else if(this.error) {
					<mat-error class="cb-error">Le formulaire de contact n'a pas pu être envoyé.</mat-error>
				}
			</form>
		</div>
	</div>
</div>
