<button
	class="cb-button"
	mat-flat-button
	[color]="this.color"
	[type]="this.type"
	[disabled]="this.disabled"
	[routerLink]="this.routerLink"
>
	@if (this.loading) {
		<mat-progress-spinner
			diameter="26"
			mode="indeterminate"
		></mat-progress-spinner>
	} @else { {{ this.text }} }
</button>
