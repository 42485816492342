import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from "@angular/core";
import { Price } from "../../models/price";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { CbButtonComponent } from "../cb-button/cb-button.component";
import { MatSelectModule } from "@angular/material/select";
import { CommonModule } from "@angular/common";

@Component({
	selector: "app-cb-quantity-selector",
	standalone: true,
	imports: [
		MatButtonModule,
		CbButtonComponent,
		MatIconModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		CommonModule,
	],
	templateUrl: "./cb-quantity-selector.component.html",
	styleUrl: "./cb-quantity-selector.component.scss",
})
export class CbQuantitySelectorComponent implements OnChanges {

	@Input() public amount: number = 1;
	@Input() public isBox: boolean = false;
	@Input() public prices!: Price[];
	@Input() public priceSelected!: Price;

	@Output() public amountEvent: EventEmitter<number> = new EventEmitter<number>();
	@Output() public priceSelectedEvent: EventEmitter<Price> = new EventEmitter<Price>();

	constructor() {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes["prices"]) {
			this.amount = 1;
			this.updateAmount();
		}
	}

	public increase(): void {
		this.amount < 20 ? this.amount++ : this.amount;
		this.updateAmount();
	}

	public decrease(): void {
		this.amount <= 1 ? this.amount : this.amount--;
		this.updateAmount();
	}

	public updateSelectedPrice = (): void => this.priceSelectedEvent.emit(this.priceSelected);
	public updateAmount = (): void => this.amountEvent.emit(this.amount);
}
