import { Component, Input } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RouterModule } from "@angular/router";

@Component({
	selector: "app-cb-button",
	standalone: true,
	imports: [
		RouterModule,
		MatButtonModule,
		MatProgressSpinnerModule
	],
	templateUrl: "./cb-button.component.html",
	styleUrl: "./cb-button.component.scss",
})
export class CbButtonComponent {

	@Input() public color: string = "basic";
	@Input() public disabled: boolean = false;
	@Input() public loading: boolean = false;
	@Input() public text: string = "button";
	@Input() public type: 'button' | 'submit' = "button";
	@Input() public routerLink?: string | (string|number)[];

}
