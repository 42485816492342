import { Component } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { MatBadgeModule } from "@angular/material/badge";
import { MatIconModule } from "@angular/material/icon";

import { ADMIN_LINKS } from "../admin-links";
import { SITEMAP, SiteMap } from "../../_commons/sitemap";
import { Link } from "../../_commons/models/link";
import { Order } from "../../_commons/models/order";

import { OrderService } from "../../_commons/services/order/order.service";

@Component({
	selector: "app-admin-menu",
	standalone: true,
	imports: [
		RouterModule,
		MatBadgeModule,
		MatIconModule
	],
	templateUrl: "./admin-menu.component.html",
	styleUrl: "./admin-menu.component.scss",
})
export class AdminMenuComponent {

	public links: Link[] = ADMIN_LINKS;
	public orders: Order[] = [];
	public orderRemains: Order[] = [];
	public sitemap: SiteMap = SITEMAP;
	public url!: string;

	constructor(
		private router: Router,
		private _order: OrderService
	) {
		this._order.currentOrders.subscribe((orders: Order[]) => {
			this.orders = orders;
			this.orderRemains = this.orders.filter((order: Order) => [3,4,5].includes(order.status.id));
		});
		this.router.events.subscribe(() => (this.url = this.router.url));
	}

}
