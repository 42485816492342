<h2 class="cb-title">Contenants</h2>

<div class="cb-admin-settings-containers">
  @if (this.productContainers.length) {
    <table mat-table [dataSource]="this.productContainers">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let element">
          {{ element.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="container">
        <th mat-header-cell *matHeaderCellDef>Nom</th>
        <td mat-cell *matCellDef="let element">{{ element.container }}</td>
      </ng-container>

      <ng-container matColumnDef="weight">
        <th mat-header-cell *matHeaderCellDef>Poids</th>
        <td mat-cell *matCellDef="let element">{{ element.weight }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Modifier"
            (click)="this.openDialog(element)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            class="cb-delete-button"
            mat-icon-button
            matTooltip="Supprimer"
            (click)="this.delete(element)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="this.displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: this.displayedColumns;"></tr>

    </table>
  }

  @else {
    @if (this.isLoading) {
      <div class="cb-admin-products-loading">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      </div>
    }

    @else {
      <p>Aucun contenant n'a été trouver</p>
    }
  }

  <button
    mat-fab
    class="cb-admin-promo-codes-button"
    color="accent"
    matTooltip="Ajouter"
    (click)="this.openDialog()"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>