import { BehaviorSubject, Observable } from 'rxjs';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class SignService {

	private signMailKey: string = 'sign-mail';

	private approvalMailAddress: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(this.getItem(this.signMailKey));
	public currentMailAddress: Observable<string | null> = this.approvalMailAddress.asObservable();

	constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

	private setItem(key: string, value: string): void {
		if(isPlatformBrowser(this.platformId))
			localStorage.setItem(key, value);
	}

	private getItem(key: string): any {
		if(isPlatformBrowser(this.platformId)) {
			const item = localStorage.getItem(key);
			return item || null;
		}

		return null;
	}

	private removeItem(key: string): void {
		if(isPlatformBrowser(this.platformId))
			localStorage.removeItem(key);
	}

	public resetMailAddress(): void {
		this.approvalMailAddress.next(null);
		this.removeItem(this.signMailKey);
	}

	public updateMailAddress(mail: string): void {
		this.approvalMailAddress.next(mail);
		this.setItem(this.signMailKey, mail);
	}

	public getMailAddress = (): string | null => this.approvalMailAddress.getValue();

}
