import { Component, Input, CUSTOM_ELEMENTS_SCHEMA, Output, EventEmitter, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";

import { Address } from "../../models/address";

import { CbAddressFormComponent } from "./cb-address-form/cb-address-form.component";
import { CbAddressItemComponent } from "./cb-address-item/cb-address-item.component";

@Component({
	selector: "app-cb-address-selector",
	standalone: true,
	imports: [
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatProgressSpinnerModule,
		MatButtonModule,
		MatIconModule,
		MatRadioModule,
		CbAddressItemComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	templateUrl: "./cb-address-selector.component.html",
	styleUrl: "./cb-address-selector.component.scss",
})
export class CbAddressSelectorComponent {

	@Input() public addAddress: boolean = true;
	@Input() public addresses: Address[] = [];
	@Input() public address?: Address;
	@Input() public disabled: boolean = false;
	@Input() public options: boolean = true;
	@Input() public selectable: boolean = true;

	@Output() public addressEvent: EventEmitter<Address> = new EventEmitter<Address>();

	public deletion: boolean = false;

	constructor(public dialog: MatDialog) {}
	
	public update = (address: Address): void => this.addressEvent.emit(address);
	public startDeletion = (): boolean => this.deletion = true;
	public doneDeletion = (): boolean => this.deletion = false;

	public openDialog(address?: Address): void {
		this.dialog.open(CbAddressFormComponent, {
			disableClose: true,
			data: address,
		});
	}

}
