import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-metrics',
  standalone: true,
  imports: [],
  templateUrl: './admin-metrics.component.html',
  styleUrl: './admin-metrics.component.scss'
})
export class AdminMetricsComponent {

}
