import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ProductContainer } from '../../models/product-container';

import { AccountService } from '../account/account.service';
import { ApiProductContainerService } from '../api/api-product-container/api-product-container.service';
import { HttpResponse } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ProductContainerService {

	private token: string = '';

	private approvalProductContainers: BehaviorSubject<ProductContainer[]> = new BehaviorSubject<ProductContainer[]>([]);
	public currentProductContainers: Observable<ProductContainer[]> = this.approvalProductContainers.asObservable();

	constructor(
		private _account: AccountService,
		private _productContainer: ApiProductContainerService
	) {
		this._account.currentUserToken.subscribe((token: string | null) => this.token = token || '');
		this.getProductContainers();
	}

	public async getProductContainers(): Promise<ProductContainer[]> {
		const productContainers: ProductContainer[] = (await this._productContainer.getAll(this.token))?.body || [];
		this.approvalProductContainers.next(productContainers);

		return this.approvalProductContainers.getValue();
	}


	public async addProductContainer(productContainer: ProductContainer): Promise<ProductContainer | null> {
		try {
			const request: ProductContainer = (await this._productContainer.add(this.token, {
				...productContainer,
			}))?.body!;

			if(request) {
				const productContainers: ProductContainer[] = await this.getProductContainers();
				productContainers.push(request);
				this.approvalProductContainers.next(productContainers);

				return request;
			}
		}

		catch (err) {}

		return null;
	}

	public async updateProductContainers(productContainer: ProductContainer): Promise<ProductContainer | null> {
		try {
			const request: ProductContainer = (await this._productContainer.update(this.token, productContainer.id, {
				...productContainer,
			}))?.body!;

			if(request) {
				const productContainers: ProductContainer[] = this.approvalProductContainers.getValue()
					.map((pc: ProductContainer) => pc.id === productContainer.id ? productContainer : pc);

				this.approvalProductContainers.next(productContainers);

				return request;
			}
		}

		catch (err) {}

		return null;
	}

	public async removeProductContainer(id: number): Promise<boolean> {
		try {
			const request: HttpResponse<void> | undefined = (await this._productContainer.delete(this.token, id));

			if(request && request.ok) {
				const productContainers: ProductContainer[] = this.approvalProductContainers.getValue()
					.filter((pc: ProductContainer) => pc.id !== id);

				this.approvalProductContainers.next(productContainers);

				return true;
			}
		}

		catch (err) {}

		return false;
	}

}
