import { Component, Inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Category } from '../../../_commons/models/category';

import { AccountService } from '../../../_commons/services/account/account.service';
import { ApiCategoryService } from '../../../_commons/services/api/api-category/api-category.service';

@Component({
	selector: 'app-admin-categories-modal',
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatDialogModule,
		MatFormFieldModule,
		MatInputModule,
		MatProgressSpinnerModule,
	],
	templateUrl: './admin-categories-modal.component.html',
	styleUrl: './admin-categories-modal.component.scss'
})
export class AdminCategoriesModalComponent {

	private token: string = '';

	public isEditMode: boolean = false;
	public submitted: boolean = false;
	public slugString: string = '';

	public categoryForm: FormGroup = this.fb.group({
		name: [null, Validators.required],
		slug: [null, [Validators.required, Validators.pattern(/^([0-9]|[a-z]|[A-Z]|\-)*$/)]],
	});

	constructor(
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<AdminCategoriesModalComponent>,
		private sb: MatSnackBar,
		private _account: AccountService,
		private _category: ApiCategoryService,
		@Inject(MAT_DIALOG_DATA) private data: Category
	) {
		this._account.currentUserToken.subscribe((token: string | null) => this.token = token || '');

		if(data) {
			this.isEditMode = true;

			this.categoryForm.setValue({
				name: data.name,
				slug: data.slug
			});

			this.slugString = data.slug;
		}
	}

	public updateSlug(name: string): void {
		this.slugString = name
			.toLowerCase()
			.replace(/[*-+ ?!,/=.';]+/g, '-')
			.replace(/[éèêë]/g, 'e')
			.replace(/[àâä]/g, 'a')
			.replace(/[îï]/g, 'i')
			.replace(/[ôö]/g, 'o')
			.replace(/[üû]/g, 'u');
	}

	public async submit(): Promise<void> {
		if(this.categoryForm.invalid)
			return;

		this.submitted = true;
		this.categoryForm.disable();

		if(this.isEditMode) {
			try {
				const request: HttpResponse<Category> | undefined = await this._category.update(this.token, this.data.id, { ...this.categoryForm.value });
	
				if(request && request.ok) {
					this.sb.open(`Catégorie ${this.categoryForm.value.name} modifiée`, "Fermer", {
						duration: 3000,
							horizontalPosition: 'end',
							verticalPosition: 'top'
					});
	
					this.dialogRef.close({ success: true });
					return;
				}
			}
	
			catch (err) {}
	
			this.sb.open("Échec de la modification", "Fermer", {
				duration: 3000,
					horizontalPosition: 'end',
					verticalPosition: 'top'
			});
		}

		else {
			try {
				const request: HttpResponse<Category> | undefined = await this._category.add(this.token, { ...this.categoryForm.value });
	
				if(request && request.ok) {
					this.sb.open(`Catégorie ${this.categoryForm.value.name} ajoutée`, "Fermer", {
						duration: 3000,
							horizontalPosition: 'end',
							verticalPosition: 'top'
					});
	
					this.dialogRef.close({ success: true });
					return;
				}
			}
	
			catch (err) {}
	
			this.sb.open("Échec de l'ajout", "Fermer", {
				duration: 3000,
					horizontalPosition: 'end',
					verticalPosition: 'top'
			});

		}

		this.submitted = false;
		this.categoryForm.enable();
	}

}
