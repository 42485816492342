<div class="cb-admin-categories">

	<div class="cb-section-header">
		<h2 class="cb-title">Catégories</h2>
	</div>

	@for (category of this.categories; track category) {
		<mat-card>
			<mat-card-content>
				<mat-icon>category</mat-icon>
				<p>{{ category.name }}</p>
			</mat-card-content>
			<mat-card-actions>
				<button
					mat-icon-button
					matTooltip="Modifier"
					(click)="this.openDialog(category)"
				>
					<mat-icon>edit</mat-icon>
				</button>

				<button
					class="cb-admin-categories-delete-button"
					mat-icon-button
					matTooltip="Supprimer"
					(click)="this.delete(category)"
				>
					<mat-icon>delete</mat-icon>
				</button>
			</mat-card-actions>
		</mat-card>
	}

	@empty {
		@if (this.isLoading) {
			<div class="cb-admin-products-loading">
				<mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
			</div>
		}

		@else { Aucune catégorie n'a été trouvé }
	}

	<button
		mat-fab
		class="cb-admin-categories-button"
		color="accent"
		matTooltip="Ajouter"
		(click)="this.openDialog()"
	>
		<mat-icon>add</mat-icon>
	</button>

</div>