import { ViewEncapsulation, Component } from "@angular/core";
import {
	FormBuilder,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { CbButtonComponent } from "../../../../_commons/components/cb-button/cb-button.component";
import { SITEMAP, SiteMap } from "../../../../_commons/sitemap";

@Component({
	selector: "app-account-details-reset-password-end",
	standalone: true,
	imports: [
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatFormFieldModule,
		CbButtonComponent,
	],
	templateUrl: "./account-details-reset-password-end.component.html",
	styleUrl: "./account-details-reset-password-end.component.scss",
})
export class AccountDetailsResetPasswordEndComponent {

	public sitemap: SiteMap = SITEMAP;

	public infoFormGrp: FormGroup = this.formBuilder.group({
		password: [null, [Validators.required, Validators.minLength(8)]],
		repeatPassword: [null, [Validators.required, Validators.minLength(8)]],
	});

	constructor(private formBuilder: FormBuilder) {}

	public ngOnInit(): void {}

	public async onSubmit(): Promise<void> {}

}
