import { Component, Inject, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from "@angular/material/table";
import saveAs from "file-saver";
import * as XLSX from "xlsx";

import { OrderedProduct } from "../../../_commons/models/ordered-product";
import { Order } from "../../../_commons/models/order";
import { ToPricePipe } from "../../../_commons/pipes/to-price/to-price.pipe";
import { InvoiceService } from "../../../_commons/services/invoice/invoice.service";

@Component({
	selector: "app-admin-orders-modal",
	standalone: true,
	imports: [MatButtonModule, MatDialogModule, MatTableModule, MatIconModule, ToPricePipe],
	templateUrl: "./admin-orders-modal.component.html",
	styleUrl: "./admin-orders-modal.component.scss",
})
export class AdminOrdersModalComponent implements OnInit {
	public dataSource!: OrderedProduct[];
	public displayedColumns: string[] = [];

	constructor(@Inject(MAT_DIALOG_DATA) private data: Order, private invoiceService: InvoiceService) {
		this.initializeDisplayedColumns();
	}

	public ngOnInit(): void {
		this.dataSource = this.data.cart.orderedProducts;
	}

	private initializeDisplayedColumns(): void {
		this.displayedColumns = ["id", "produit", "prix", "quantité", "total", "facturedAddress"];
	}


	public exportToPDF(): void {
		this.invoiceService.generatePDF(this.data);
	}

	private generateXLSX(): Blob {
		const formattedData = this.data.cart.orderedProducts.map((item) => ({
			id: item.id,
			quantity: item.quantity,
			product: item.product.name,
			price: item.price.value,
			amount: item.quantity * item.price.value,
		}));

		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(formattedData);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "OrderedProducts");

		const wbout: Blob = new Blob([XLSX.write(wb, { bookType: "xlsx", type: "array" })], {
			type: "application/octet-stream",
		});

		return wbout;
	}

	public exportToExcel(): void {
		const wbout = this.generateXLSX();
		saveAs(wbout, `CARREMENT_BON_${this.data.id}.xlsx`);
	}
}
