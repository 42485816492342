<div class="cb-user-manager">
	<div class="cb-section-header">
		<h2 class="cb-title">Clients</h2>

		<mat-form-field class="cb-search">
			<mat-label>Recherche</mat-label>
			<input matInput type="text" #search />
			<mat-icon matPrefix>search</mat-icon>
		</mat-form-field>
	</div>

	<div class="cb-users">
		<div class="cb-users-header">
			<mat-checkbox></mat-checkbox>

			<span class="cb-column">e-mail</span>
			<span class="cb-column">nom, prénom</span>
			<span class="cb-column">date d'inscription</span>
			<span class="cb-column">role</span>
			<span class="cb-column">actions</span>
		</div>

		<mat-accordion [hideToggle]="false" multi>
			@if (!search.value) {
				@for (user of this.users; track user) {
					<mat-expansion-panel class="cb-users-user" hideToggle>
						<mat-expansion-panel-header>
							<mat-panel-description>
								<mat-checkbox></mat-checkbox>
								<span class="cb-column">{{ user.email }}</span>
								<span class="cb-column">{{ user.profile.firstName }} {{ user.profile.lastName }}</span>
								<span class="cb-column">{{ (user.registerDate | toDate).toLocaleDateString("fr") }}</span>
								<span class="cb-column">{{ user.role.name === 'ADMIN' ? 'Administrateur' : 'Utilisateur' }}</span>
	
								<span class="cb-column">
									<mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
								</span>
	
								<mat-menu #menu="matMenu">
									<button (click)="this.resetUserPassword(user.email)" mat-menu-item>
										<mat-icon>lock_reset</mat-icon>Réinitialiser le mot de passe
									</button>
								</mat-menu>
							</mat-panel-description>
						</mat-expansion-panel-header>

						<app-admin-users-item [user]="user"></app-admin-users-item>
					</mat-expansion-panel>
				}
	
				@empty { <p>Aucun client n'a été trouvé</p> }
			}

			@if (search.value) {
				@for (user of this.users | searchUser : search.value; track user) {
					<mat-expansion-panel class="cb-users-user" hideToggle>
						<mat-expansion-panel-header>
							<mat-panel-description>
								<mat-checkbox></mat-checkbox>
								<span class="cb-column">{{ user.email }}</span>
								<span class="cb-column">{{ user.profile.firstName }} {{ user.profile.lastName }}</span>
								<span class="cb-column">{{ (user.registerDate | toDate).toLocaleDateString("fr") }}</span>
								<span class="cb-column">{{ user.role.name === 'ADMIN' ? 'Administrateur' : 'Utilisateur' }}</span>
	
								<span class="cb-column">
									<mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
								</span>
	
								<mat-menu #menu="matMenu">
									<button (click)="this.resetUserPassword(user.email)" mat-menu-item>
										<mat-icon>lock_reset</mat-icon>Réinitialiser le mot de passe
									</button>
								</mat-menu>
							</mat-panel-description>
						</mat-expansion-panel-header>
					</mat-expansion-panel>
				}
			}
		</mat-accordion>
	</div>
</div>
