<div class="cb-address-item">
	@if (this.address) {
		<div class="cb-address-item-info">
			<p>{{ this.address.firstName }} {{ this.address.lastName }}</p>
			<p>{{ this.address.street }}</p>
			<p>{{ this.address.city }} {{ this.address.zip }} {{ this.country }}</p>
			<p>{{ this.address.phone }}</p>
		</div>

		<div class="cb-address-item-buttons">
			@if (this.options) {
				@if (this.address.isDefault) {
					<span class="cb-address-item-buttons-button">
						<mat-icon matTooltip="Adresse par défaut">star</mat-icon>
					</span>
				}

				<button
					mat-icon-button
					matTooltip="Modifier"
					class="cb-address-item-buttons-button"
					(click)="this.openDialog()"
					[disabled]="this.disabled"
				>
					<mat-icon>edit</mat-icon>
				</button>

				<button
					mat-icon-button
					matTooltip="Supprimer"
					class="cb-address-item-buttons-button"
					(click)="this.deleteAddress()"
					[disabled]="this.deletionProgress || this.disabled"
				>
					<mat-icon>delete</mat-icon>
				</button>
			}
		</div>
	}
</div>
