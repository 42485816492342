import { Injectable } from "@angular/core";
import { Order } from "../../models/order";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Address } from "../../models/address";
import { Content, StyleDictionary, TDocumentDefinitions } from "pdfmake/interfaces";
import { SHOP_ADDRESSES } from "../../constants";
import { LOGO_SVG } from "./logo-svg";

@Injectable({
	providedIn: "root",
})
export class InvoiceService {
	private taxRate: number = 0.055;

	constructor() {
		(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
	}

	public generatePDF(order: Order): void {
		const documentDefinition = this.getDocumentDefinition(order);
		(pdfMake as any).createPdf(documentDefinition).download(`Facture_${order.id}.pdf`);
	}

	private getDocumentDefinition(order: Order): TDocumentDefinitions {
		const billedAddress: Address = JSON.parse(order.billedAddress);
		const deliveredAddress: Address = JSON.parse(order.deliveredAddress);

		const shopAddress: Address = SHOP_ADDRESSES[0];

		const companyInfo = {
			name: `${shopAddress.firstName}`,
			address: `${shopAddress.street} ${shopAddress.city} ${shopAddress.zip} ${shopAddress.country}`,
			additional: `${shopAddress.lastName}`,
			email: "contact@carrementbon.fr",
			website: "https://carrementbon.fr/",
		};

		const totalAmountInWords = this.convertNumberToWords(parseFloat(this.calculateTotal(order)));

		return {
			footer: (currentPage, pageCount) => {
				return {
					columns: [
						{
							text: `CARREMENTBON - Code NAF (APE) 4637Z - N° RCS 848 898 862 R.C.S. Toulouse - Siret : 84889886200018 \n - N° TVA FR10 848898862`,
							alignment: "center",
							style: "footer",
						},
					],
					margin: [0, 0, 0, 10],
				};
			},

			background: (currentPage, pageSize) => {
				return {
					canvas: [
						{
							type: "rect",
							x: 0,
							y: 0,
							w: pageSize.width,
							h: pageSize.height,
							color: "#F7EFE6",
						},
					],
				};
			},

			content: [
				{
					columns: [
						{
							svg: LOGO_SVG,
							width: 100,
						},
					],
				},
				{
					columns: [
						{
							width: "50%",
							table: {
								widths: ["*"],
								body: [
									[{ text: "Adresse de facturation", style: "tableHeader" }],
									[{ text: `${order.user.profile.firstName} ${order.user.profile.lastName}`, style: "tableContent" }],
									[{ text: billedAddress.street, style: "tableContent" }],
									[{ text: `${billedAddress.zip} ${billedAddress.zip} ${billedAddress.city} ${billedAddress.country}`, style: "tableContent" }],
									[{ text: `Téléphone : ${billedAddress.phone}`, style: "tableContent" }],
									[{ text: `N° Client : ${order.user.id.toString()}`, style: "tableContent" }],
								],
							},
							layout: "noBorders",
						},
						{
							width: "50%",
							table: {
								widths: ["*"],
								body: [
									[{ text: "Adresse de livraison", style: "tableHeader" }],
									[{ text: `${deliveredAddress.firstName} ${deliveredAddress.lastName}`, style: "tableContent" }],
									[{ text: deliveredAddress.street, style: "tableContent" }],
									[{ text: `${deliveredAddress.zip} ${deliveredAddress.city} ${deliveredAddress.country}`, style: "tableContent" }],
								],
							},
							layout: "noBorders",
						},
					],
					columnGap: 20,
					margin: [0, 0, 0, 20],
				},
				{ text: `Nouvelle commande passée le  ${new Date().toLocaleDateString()}`, style: "header", margin: [0, 20, 0, 10] },
				{ text: `Référence  ${order.reference}`, style: "header", margin: [0, 20, 0, 10] },
				this.getOrderTable(order),
				{
					columns: [
						{ width: "*", text: "" },
						{
							width: "auto",
							table: {
								body: [
									[
										{ text: "Total HT", style: "totalsLabel" },
										{ text: `${this.calculateSubTotal(order).toFixed(2)} €`, style: "totalsValue" },
									],
									[
										{ text: `TVA (${(this.taxRate * 100).toFixed(1)}%)`, style: "totalsLabel" },
										{ text: `${this.calculateTax(order).toFixed(2)} €`, style: "totalsValue" },
									],
									[
										{ text: "Total TTC", style: "totalsLabel" },
										{ text: `${this.calculateTotal(order)} €`, style: "totalsValue" },
									],
								],
							},
							layout: "lightHorizontalLines",
						},
					],
					margin: [0, 20, 0, 20],
				},

				{ text: `Le montant total s'élève à ${totalAmountInWords}`, style: "amountInWords", margin: [0, 0, 0, 20] },
				[
					{ text: companyInfo.name, style: "companyName" },
					{ text: companyInfo.address, style: "companyInfo" },
					{ text: companyInfo.additional, style: "companyInfo" },
					{ text: `Email : ${companyInfo.email}`, style: "companyInfo" },
					{ text: `Site web : ${companyInfo.website}`, style: "companyInfo" },
				],
			],
			styles: this.getStyles(),
			defaultStyle: {
				fontSize: 10,
			},
		};
	}

	private getOrderTable(order: Order): Content {
		return {
			table: {
				widths: ["*", "auto", "auto", "auto"],
				body: [
					[
						{ text: "Produit", style: "tableHeader" },
						{ text: "Quantité", style: "tableHeader" },
						{ text: "Prix Unitaire HT", style: "tableHeader" },
						{ text: "Total HT", style: "tableHeader" },
					],
					...order.cart.orderedProducts.map((item) => {
						return [
							{
								text: `${item.product.name} - ${item.price.weight} g`,
								style: "tableContent",
							},
							{
								text: item.quantity.toString(),
								style: "tableContent",
								alignment: "right",
							},
							{
								text: `${item.price.value.toFixed(2)} €`,
								style: "tableContent",
								alignment: "right",
							},
							{
								text: `${(item.quantity * item.price.value).toFixed(2)} €`,
								style: "tableContent",
								alignment: "right",
							},
						];
					}),
				],
			},
			layout: "lightHorizontalLines",
		};
	}

	private getStyles(): StyleDictionary {
		return {
			h1: {
				fontSize: 22,
				bold: true,
				margin: [0, 0, 0, 10],
				color: "#331501",
			},
			h2: {
				fontSize: 18,
				bold: true,
				margin: [0, 10, 0, 5],
				color: "#331501",
			},
			h3: {
				fontSize: 16,
				bold: true,
				margin: [0, 10, 0, 5],
				color: "#331501",
			},
			paragraph: {
				fontSize: 12,
				margin: [0, 5, 0, 5],
				color: "#542302",
			},
			tableHeader: {
				fontSize: 12,
				margin: [0, 5, 0, 5],
				color: "#331501",
			},
		};
	}

	private convertNumberToWords(amount: number): string {
		const units = ["zéro", "un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf"];
		const tens = ["", "", "vingt", "trente", "quarante", "cinquante", "soixante", "soixante-dix", "quatre-vingt", "quatre-vingt-dix"];

		const euros = Math.floor(amount);
		const centimes = Math.round((amount - euros) * 100);

		let eurosInWords = "";
		if (euros < 10) {
			eurosInWords = units[euros];
		} else if (euros < 20) {
			eurosInWords = "dix-" + units[euros - 10];
		} else if (euros < 100) {
			const ten = Math.floor(euros / 10);
			const unit = euros % 10;
			eurosInWords = tens[ten];
			if (unit > 0) {
				eurosInWords += "-" + units[unit];
			}
		} else {
			eurosInWords = "cent " + (euros % 100 > 0 ? this.convertNumberToWords(euros % 100) : "");
		}

		let centimesInWords = "";
		if (centimes > 0) {
			if (centimes < 10) {
				centimesInWords = units[centimes];
			} else if (centimes < 20) {
				centimesInWords = "dix-" + units[centimes - 10];
			} else if (centimes < 100) {
				const ten = Math.floor(centimes / 10);
				const unit = centimes % 10;
				centimesInWords = tens[ten];
				if (unit > 0) {
					centimesInWords += "-" + units[unit];
				}
			}
		}

		let amountInWords = eurosInWords + " euros";
		if (centimes > 0) {
			amountInWords += " et " + centimesInWords + " centimes";
		}

		return amountInWords;
	}

	private calculateSubTotal(order: Order): number {
		return order.cart.orderedProducts.reduce((sum, item) => sum + item.quantity * item.price.value, 0);
	}

	private calculateTax(order: Order): number {
		return this.calculateSubTotal(order) * this.taxRate;
	}

	private calculateTotal(order: Order): string {
		const subTotal = this.calculateSubTotal(order);
		const tax = this.calculateTax(order);
		return (subTotal + tax).toFixed(2);
	}
}
