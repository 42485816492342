<div class="cb-quantity-selector">
	<mat-form-field class="cb-form-selector" appearance="outline">
		<mat-label>Format</mat-label>
		<mat-select
			[(ngModel)]="this.priceSelected"
			(ngModelChange)="this.updateSelectedPrice()"
		>
			@for (price of this.prices; track $index){
				@if (price.isVisible) {
					<mat-option [value]="price">
						{{ price.container.container }}
					</mat-option>
				}
			}
		</mat-select>
	</mat-form-field>

	<div class="cb-amount">
		<div class="cb-amount-title">quantité</div>

		<div class="cb-amount-selector">
			<button
				mat-icon-button
				class="cb-amount-selector-button"
				(click)="this.decrease()"
			>
				<mat-icon fontIcon="remove-circle"></mat-icon>
			</button>

			<span class="cb-amount-selector-value">{{ this.amount }}</span>

			<button
				mat-icon-button
				class="cb-amount-selector-button"
				(click)="this.increase()"
			>
				<mat-icon fontIcon="add-circle"></mat-icon>
			</button>
		</div>
	</div>
</div>
