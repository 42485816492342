<mat-dialog-content>
	@if (this.title) {
		<h2 class="cb-title">{{ this.title }}</h2>
	}

	@if (this.message) {
		<p class="cb-text">{{ this.message }}</p>
	}
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div class="cb-btn-algn">
		<app-cb-button [text]="this.buttonText || 'button'" color="primary" [mat-dialog-close]="true" cdkFocusInitial></app-cb-button>
	</div>
</mat-dialog-actions>
