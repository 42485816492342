import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { Product } from '../../_commons/models/product';

import { ReplacePipe } from '../../_commons/pipes/replace/replace.pipe';

import { AccountService } from '../../_commons/services/account/account.service';
import { ApiProductService } from '../../_commons/services/api/api-product/api-product.service';

import { AdminProductsModalComponent } from './admin-products-modal/admin-products-modal.component';

@Component({
	selector: 'app-admin-products',
	standalone: true,
	imports: [
		MatButtonModule,
		MatCardModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatTooltipModule,
		ReplacePipe
	],
	templateUrl: './admin-products.component.html',
	styleUrl: './admin-products.component.scss'
})
export class AdminProductsComponent implements OnInit {

	private token: string = '';

	public products: Product[] = [];

	public isLoading: boolean = false;

	constructor(
		private dialog: MatDialog,
		private sb: MatSnackBar,
		private _account: AccountService,
		private _product: ApiProductService
	) {
		this._account.currentUserToken.subscribe((token: string | null) => this.token = token || '');
	}

	private async getProducts(): Promise<void> {
		this.isLoading = true;
		this.products = (await this._product.getAll())?.body || [];
		this.isLoading = false;
	}

	public async ngOnInit(): Promise<void> {
		await this.getProducts();
	}

	public openDialog(product?: Product): void {
		this.dialog.open(AdminProductsModalComponent, {
			disableClose: true,
			data: product
		})
		.afterClosed().subscribe(async (data) => {
			if(data.success)
				await this.getProducts();
		});
	}

	public async delete(product: Product): Promise<void> {
		try {
			const request: HttpResponse<void> | undefined = await this._product.delete(this.token, product.id);

			if(request && request.ok) {
				this.sb.open(`${product.name} a été supprimé`, "Fermer", {
					duration: 3000,
					horizontalPosition: 'end',
					verticalPosition: 'top'
				});

				await this.getProducts();
				return;
			}
		}

		catch (err) {}

		this.sb.open("Un produit acheté ne peut être supprimé", "Fermer", {
			duration: 3000,
			horizontalPosition: 'end',
			verticalPosition: 'top'
		});
	}

}
