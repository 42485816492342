<div class="cb-account">
	@if(this.user){
		<h1 class="cb-name">
			{{ this.user.profile.firstName }} {{ this.user.profile.lastName }}
		</h1>
	}

	@if(this.address){
		<div class="cb-address-ctnr">
			<div class="cb-address">
				<p class="cb-title">{{ this.address.street }}</p>
				<p class="cb-title">
					{{ this.address.city }} {{ this.address.zip }}
					{{ this.address.country }}
				</p>
			</div>
		</div>
	}

	<div class="cb-settings-link">
		<a class="cb-link" [routerLink]="[this.sitemap.cart.route]" ariaCurrentWhenActive="page">
			mon panier
		</a>
		<a class="cb-link" [routerLink]="[this.sitemap.orders.route]" ariaCurrentWhenActive="page">
			mes commandes
		</a>
		<a
			class="cb-link"
			[routerLink]="[this.sitemap.accountDetails.route]"
			ariaCurrentWhenActive="page"
		>
			mes informations
		</a>
	</div>

	<div class="cb-btn-algn">
		<app-cb-button
			text="Se déconnecter"
			color="primary"
			[loading]="this.isDisconnecting"
			(click)="this.signOut()"
		></app-cb-button>
	</div>
</div>
