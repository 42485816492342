import { HttpHeaders, HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_URL } from "../../../constants";
import { Promotion } from "../../../models/promotion";
import { HEADERS } from "../headers";
import { PromotionDto } from "../../../dtos/promotion.dto";

@Injectable({
	providedIn: "root",
})
export class ApiPromotionService {

	private API_URL: string = API_URL;
	private headers: HttpHeaders = HEADERS;
	private PROMOTION_PATH: string = "promotion";

	constructor(private http: HttpClient) {}

	public async getAll(token: string): Promise<HttpResponse<Promotion[]> | undefined> {
		return this.http
			.get<Promotion[]>(`${this.API_URL}/${this.PROMOTION_PATH}/`, {
				headers: this.headers.append('token', token),
				observe: "response",
			})
			.toPromise();
	}

	public async getById(
		token: string,
		id: number
	): Promise<HttpResponse<Promotion> | undefined> {
		return this.http
			.get<Promotion>(`${this.API_URL}/${this.PROMOTION_PATH}/${id}`, {
				headers: this.headers.append('token', token),
				observe: "response",
			})
			.toPromise();
	}

	public async getByCode(
		token: string,
		code: string
	): Promise<HttpResponse<Promotion> | undefined> {
		return this.http
			.get<Promotion>(`${this.API_URL}/${this.PROMOTION_PATH}/code/${code}`, {
				headers: this.headers.append('token', token),
				observe: "response",
			})
			.toPromise();
	}

	public async add(
		token: string,
		dto: PromotionDto
	): Promise<HttpResponse<Promotion> | undefined> {
		return this.http
			.post<Promotion>(`${this.API_URL}/${this.PROMOTION_PATH}/`, dto, {
				headers: this.headers.append('token', token),
				observe: "response",
			})
			.toPromise();
	}

	public async update(
		token: string,
		id: number,
		dto: PromotionDto
	): Promise<HttpResponse<Promotion> | undefined> {
		return this.http
			.put<Promotion>(`${this.API_URL}/${this.PROMOTION_PATH}/${id}`, dto, {
				headers: this.headers.append('token', token),
				observe: "response",
			})
			.toPromise();
	}

	public async delete(
		token: string,
		id: number
	): Promise<HttpResponse<void> | undefined> {
		return this.http
			.delete<void>(`${this.API_URL}/${this.PROMOTION_PATH}/${id}`, {
				headers: this.headers.append('token', token),
				observe: "response",
			})
			.toPromise();
	}
}
