import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { SITEMAP } from "./_commons/sitemap";

import { ErrorComponent } from "./error/error.component";
import { MaintenanceComponent } from "./maintenance/maintenance.component";

import { MainComponent } from "./main/main.component";
import { HomeComponent } from "./main/home/home.component";
import { AccountComponent } from "./main/account/account.component";
import { AccountIndexComponent } from "./main/account/account-index/account-index.component";
import { AccountDetailsComponent } from "./main/account/account-details/account-details.component";
import { AccountDetailsIndexComponent } from "./main/account/account-details/account-details-index/account-details-index.component";
import { AccountDetailsResetPasswordComponent } from "./main/account/account-details/account-details-reset-password/account-details-reset-password.component";
import { AccountDetailsResetPasswordEndComponent } from "./main/account/account-details/account-details-reset-password-end/account-details-reset-password-end.component";
import { OrderComponent } from "./main/order/order.component";
//import { AccountOrdersComponent } from "./main/account/account-orders/account-orders.component";
//import { AccountOrdersDetailsComponent } from "./main/account/account-orders/account-orders-details/account-orders-details.component";
import { ShopComponent } from "./main/shop/shop.component";
import { ProductsComponent } from "./main/shop/products/products.component";
import { ProductComponent } from "./main/shop/products/product/product.component";
import { CartComponent } from "./main/cart/cart.component";
import { ActivateCardComponent } from "./main/activate-card/activate-card.component";
import { ConditionsComponent } from "./main/conditions/conditions.component";
import { LegalNoticeComponent } from "./main/legal-notice/legal-notice.component";
import { FaqComponent } from "./main/faq/faq.component";
import { ContactComponent } from "./main/contact/contact.component";
import { SignComponent } from "./sign/sign.component";
import { SignIndexComponent } from "./sign/sign-index/sign-index.component";
import { SignUpProcessComponent } from "./sign/sign-up-process/sign-up-process.component";
import { ForgotPasswordComponent } from "./sign/forgot-password/forgot-password.component";

import { CheckoutComponent } from "./checkout/checkout.component";
import { CheckoutStartComponent } from "./checkout/checkout-start/checkout-start.component";
import { CheckoutReadyComponent } from "./checkout/checkout-ready/checkout-ready.component";
import { CheckoutEndComponent } from "./checkout/checkout-end/checkout-end.component";
import { AdminComponent } from "./admin/admin.component";
import { AdminIndexComponent } from "./admin/admin-index/admin-index.component";
import { AdminCategoriesComponent } from "./admin/admin-categories/admin-categories.component";
import { AdminProductsComponent } from "./admin/admin-products/admin-products.component";
import { AdminPromoCodeComponent } from "./admin/admin-promo-codes/admin-promo-codes.component";
import { AdminOrdersComponent } from "./admin/admin-orders/admin-orders.component";
import { AdminUsersComponent } from "./admin/admin-users/admin-users.component";
import { AdminMetricsComponent } from "./admin/admin-metrics/admin-metrics.component";
import { AdminSettingsComponent } from "./admin/admin-settings/admin-settings.component";
import { AdminSettingsColissimoComponent } from "./admin/admin-settings/admin-settings-colissimo/admin-settings-colissimo.component";
import { AdminToolsComponent } from "./admin/admin-tools/admin-tools.component";

import { isAdminGuard } from "./_commons/guards/is-admin/is-admin.guard";
import { isCartEmptyGuard } from "./_commons/guards/is-cart-empty/is-cart-empty.guard";
import { isNotSignedInGuard } from "./_commons/guards/is-not-signed-in/is-not-signed-in.guard";
import { isSignedInGuard } from "./_commons/guards/is-signed-in/is-signed-in.guard";
import { AdminSettingsMondialRelayComponent } from "./admin/admin-settings/admin-settings-mondial-relay/admin-settings-mondial-relay.component";
import { AdminSettingsContainersComponent } from "./admin/admin-settings/admin-settings-containers/admin-settings-containers.component";

export const routes: Routes = [
	{
		path: SITEMAP.main.path,
		component: MainComponent,
		children: [
			{ path: "", component: HomeComponent },
			{
				path: SITEMAP.account.path,
				component: AccountComponent,
				canActivate: [ isSignedInGuard ],
				children: [
					{ path: "", component: AccountIndexComponent },
					{
						path: SITEMAP.accountDetails.path,
						component: AccountDetailsComponent,
						children: [
							{ path: "", component: AccountDetailsIndexComponent },
							{ path: SITEMAP.accountDetailsResetPassword.path, component: AccountDetailsResetPasswordComponent },
							{ path: SITEMAP.accountDetailsResetPasswordEnd.path, component: AccountDetailsResetPasswordEndComponent }
						]
					},
					//{ path: SITEMAP.accountOrders.path, component: AccountOrdersComponent },
					//{ path: SITEMAP.accountOrder.path, component: AccountOrdersDetailsComponent }
				],
			},
			{
				path: SITEMAP.orders.path,
				canActivate: [ isSignedInGuard ],
				component: OrderComponent
			},
			{
				path: SITEMAP.shop.path,
				component: ShopComponent,
				children: [
					{ path: "", component: ProductsComponent },
					{ path: SITEMAP.shopProducts.path, component: ProductsComponent }
				]
			},
			{ path: SITEMAP.shopProduct.path, component: ProductComponent },
			{ path: SITEMAP.cart.path, component: CartComponent },
			{ path: SITEMAP.activateCard.path, component: ActivateCardComponent },
			{ path: SITEMAP.conditions.path, component: ConditionsComponent },
			{ path: SITEMAP.legalNotice.path, component: LegalNoticeComponent },
			{ path: SITEMAP.contact.path, component: ContactComponent },
			{ path: SITEMAP.faq.path, component: FaqComponent }
		],
	},
	{
		path: SITEMAP.admin.path,
		canActivate: [ isAdminGuard ],
		component: AdminComponent,
		children: [
			{ path: "", component: AdminIndexComponent },
			{ path: SITEMAP.adminCategories.path, component: AdminCategoriesComponent },
			{ path: SITEMAP.adminProducts.path, component: AdminProductsComponent },
			{ path: SITEMAP.adminPromoCodes.path, component: AdminPromoCodeComponent },
			{ path: SITEMAP.adminOrders.path, component: AdminOrdersComponent },
			{ path: SITEMAP.adminUsers.path, component: AdminUsersComponent },
			{ path: SITEMAP.adminMetrics.path, component: AdminMetricsComponent },
			{ path: SITEMAP.adminSettings.path, component: AdminSettingsComponent },
			{ path: SITEMAP.adminSettingsContainers.path, component: AdminSettingsContainersComponent },
			{ path: SITEMAP.adminSettingsColissimo.path, component: AdminSettingsColissimoComponent },
			{ path: SITEMAP.adminSettingsMondialRelay.path, component: AdminSettingsMondialRelayComponent },
			{ path: SITEMAP.adminTools.path, component: AdminToolsComponent }
		],
	},
	{
		path: SITEMAP.checkout.path,
		canActivate: [ isSignedInGuard, isCartEmptyGuard ],
		component: CheckoutComponent,
		children: [
			{ path: "", component: CheckoutStartComponent },
			{ path: SITEMAP.checkoutReady.path, component: CheckoutReadyComponent },
			{ path: SITEMAP.checkoutEnd.path, component: CheckoutEndComponent },
		],
	},
	{
		path: SITEMAP.sign.path,
		canActivate: [ isNotSignedInGuard ],
		component: SignComponent,
		children: [
			{ path: "", component: SignIndexComponent },
			{ path: SITEMAP.signUpProcess.path, component: SignUpProcessComponent },
			{ path: SITEMAP.signForgotPassword.path, component: ForgotPasswordComponent },
		],
	},
	{ path: SITEMAP.maintenance.path, component: MaintenanceComponent },
	{ path: "**", component: ErrorComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
