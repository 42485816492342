import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { API_URL } from '../../../constants';
import { HEADERS } from '../headers';
import { Role } from '../../../models/role';

@Injectable({
	providedIn: 'root'
})
export class ApiRoleService {

	private API_URL: string = API_URL;
	private headers: HttpHeaders = HEADERS;
	private ROLE_PATH: string = "role";

	constructor(private http: HttpClient) {}

	public async getAll(token: string): Promise<HttpResponse<Role[]> | undefined> {
		return this.http
			.get<Role[]>(`${this.API_URL}/${this.ROLE_PATH}/`, {
				headers: this.headers.append("token", token),
				observe: 'response'
			})
			.toPromise();
	}

	public async getById(token: string, id: number): Promise<HttpResponse<Role> | undefined> {
		return this.http
			.get<Role>(`${this.API_URL}/${this.ROLE_PATH}/${id}`, {
				headers: this.headers.append("token", token),
				observe: 'response'
			})
			.toPromise();
	}

}
