<div class="cb-order-manager">
	<div class="cb-section-header">
		<h2 class="cb-title">Commandes</h2>
		<!-- <mat-icon>file_open</mat-icon>
		<mat-icon>delete_forever</mat-icon> -->

		<mat-form-field class="cb-search">
			<mat-label>Recherche</mat-label>
			<input matInput type="text" #search />
			<mat-icon matPrefix>search</mat-icon>
		</mat-form-field>
	</div>

	<div class="cb-orders">
		<div class="cb-orders-header">
			<mat-checkbox></mat-checkbox>

			<span class="cb-column">id</span>
			<span class="cb-column">client</span>
			<span class="cb-column">date</span>
			<span class="cb-column">montant</span>
			<span class="cb-column">status</span>
			<span class="cb-column">actions</span>
		</div>

		<mat-accordion [hideToggle]="false" multi>
			@if (!search.value) { @for (order of this.orders; track order) {
			<mat-expansion-panel
				class="cb-orders-order"
				[matBadge]="[3, 4, 5, 7].includes(order.status.id) ? '.' : ''"
				matBadgePosition="above before"
				hideToggle
			>
				<mat-expansion-panel-header>
					<mat-panel-description>
						<mat-checkbox></mat-checkbox>
						<span class="cb-column">{{ order.id }}</span>
						<span class="cb-column">
							{{ order.user.profile.firstName }}
							{{ order.user.profile.lastName }}
						</span>
						<span class="cb-column">
							{{ (order.date | toDate).toLocaleDateString("fr") }}
						</span>
						<span class="cb-column">{{ order.amount | toPrice }} €</span>
						<span class="cb-column">{{ order.status.status }}</span>

						<span class="cb-column">
							<mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
						</span>

						<mat-menu #menu="matMenu">
							<button (click)="this.openDialog(order)" mat-menu-item><mat-icon>format_list_bulleted</mat-icon>Contenu de la commande</button>
							<button (click)="exportToExcel(order)" mat-menu-item><mat-icon>download</mat-icon>Exporter en Excel</button>
							@if ((order.deliveredAddress | toAddress).MRID) {
								<button
									mat-menu-item
									[disabled]="[1, 2, 6, 8].includes(order.status.id)"
									(click)="downloadMondialRelay(order)"
								>
									<mat-icon>qr_code_2</mat-icon>Télécharger le bordereau Mondial Relay
								</button>
							}

							@if (!this.isShopAddresses(order.deliveredAddress) && !(order.deliveredAddress | toAddress).MRID) {
							<button
								mat-menu-item
								[disabled]="[1, 2, 6, 8].includes(order.status.id)"
								[matTooltip]="
									[2].includes(order.status.id)
										? 'La commande n\'a pas encore été payé'
										: [6, 8].includes(order.status.id)
										? 'La commande a été livrée / retirée'
										: [1].includes(order.status.id)
										? 'La commande a été annulée'
										: ''
								"
								(click)="downloadColissimo(order)"
							>
								<mat-icon>qr_code_2</mat-icon>Télécharger le bordereau Colissimo
							</button>
							}
						</mat-menu>
					</mat-panel-description>
				</mat-expansion-panel-header>

				<app-admin-orders-item [order]="order"></app-admin-orders-item>
			</mat-expansion-panel>
			} @empty { @if (this.isLoading) {
			<div class="cb-admin-products-loading">
				<mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
			</div>
			} @else { Aucune commande n'a été trouvée } } } @if (search.value) { @for (order of this.orders | searchOrder : search.value; track order) {
			<mat-expansion-panel class="cb-orders-order" hideToggle>
				<mat-expansion-panel-header>
					<mat-panel-description>
						<mat-checkbox></mat-checkbox>
						<span class="cb-column">{{ order.id }}</span>
						<span class="cb-column">
							{{ order.user.profile.firstName }}
							{{ order.user.profile.lastName }}
						</span>
						<span class="cb-column">
							{{ (order.date | toDate).toLocaleDateString("fr") }}
						</span>
						<span class="cb-column">{{ order.amount | toPrice }} €</span>
						<span class="cb-column">{{ order.status.status }}</span>

						<span class="cb-column">
							<mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
						</span>

						<mat-menu #menu="matMenu">
							<button (click)="this.openDialog(order)" mat-menu-item><mat-icon>format_list_bulleted</mat-icon>Contenu de la commande</button>
							<button (click)="exportToExcel(order)" mat-menu-item><mat-icon>download</mat-icon>Exporter en Excel</button>
							@if (!this.isShopAddresses(order.deliveredAddress)) {
							<button
								mat-menu-item
								[disabled]="[1, 2, 6, 8].includes(order.status.id)"
								[matTooltip]="
									[2].includes(order.status.id)
										? 'La commande n\'a pas encore été payé'
										: [6, 8].includes(order.status.id)
										? 'La commande a été livrée / retirée'
										: [1].includes(order.status.id)
										? 'La commande a été annulée'
										: ''
								"
								(click)="downloadColissimo(order)"
							>
								<mat-icon>qr_code_2</mat-icon>Télécharger le bordereau Colissimo
							</button>
							}
						</mat-menu>
					</mat-panel-description>
				</mat-expansion-panel-header>

				<app-admin-orders-item [order]="order"></app-admin-orders-item>
			</mat-expansion-panel>
			} @empty {
			<p>Aucune commande n'a été trouvé</p>
			} }
		</mat-accordion>
	</div>
</div>
