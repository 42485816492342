import { Pipe, PipeTransform } from '@angular/core';

import { User } from '../../models/user';

@Pipe({
	name: 'searchUser',
	standalone: true
})
export class SearchUserPipe implements PipeTransform {

	public transform = (value: User[], email: string): User[] =>
		value.filter((v: User) => v.email.includes(email));

}
