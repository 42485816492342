<div class="cb-error">

	<mat-icon class="cb-error-icon">sentiment_very_dissatisfied</mat-icon>

	<h1>page introuvable</h1>
	<h2>la page que vous avez demandée n'existe plus</h2>

	<app-cb-button
		color="primary"
		text="retour à l'accueil"
		[routerLink]="[this.sitemap.main.route]"
	></app-cb-button>

</div>