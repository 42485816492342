import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { Category } from '../../_commons/models/category';

import { ApiCategoryService } from '../../_commons/services/api/api-category/api-category.service';

import { CategoriesComponent } from './categories/categories.component';
import { ProductsComponent } from './products/products.component';

@Component({
	selector: 'app-shop',
	standalone: true,
	imports: [
		RouterModule,
		CategoriesComponent
	],
	templateUrl: './shop.component.html',
	styleUrl: './shop.component.scss'
})
export class ShopComponent implements OnInit {

	public categories: Category[] = []

	constructor(private _category: ApiCategoryService) {}

	public async ngOnInit(): Promise<void> {
		this.categories = (await this._category.getAll())?.body!;
	}

}
