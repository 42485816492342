<div class="cb-admin-path">

	<mat-icon>{{ this.icon }}</mat-icon>

	@if (this.url) {
		@for (part of this.url.split('/'); track $index) {
			<span class="cb-path-item">
				@if(!($index === 0)) { <span>/</span> }
				<span [routerLink]="($index === 1) ? [this.sitemap.admin.route] : [this.sitemap.admin.route, part]">{{ part }}</span>
			</span>
		}
	}

</div>