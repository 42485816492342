<ul class="cb-categories">

	<li class="cb-categories-category">
		<a [class]="this.router.url === this.sitemap.shop.route ? 'active' : ''" [routerLink]="[this.sitemap.shop.route]">boutique</a>
	</li>

	@for (category of this.categories; track $index) {
		<li class="cb-categories-category">
			<a
				[class]="this.router.url === (this.sitemap.shop.route+'/{0}' | replace : '{0}' : category.slug) ? 'active' : ''"
				[routerLink]="[ category.slug ]"
			>{{ category.name }}</a>
		</li>
	}

</ul>