export const LOGO_SVG = `<svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="52" cy="52" r="52" fill="#E8C387"/>
<path d="M52 3.9C55.3 3.9 58.5 4.2 61.7 4.9C64.8 5.5 67.8 6.5 70.7 7.7C73.6 8.9 76.3 10.4 78.9 12.1C81.4 13.8 83.8 15.8 86 18C88.2 20.2 90.2 22.6 91.9 25.1C93.6 27.7 95.1 30.4 96.3 33.3C97.5 36.2 98.5 39.3 99.1 42.3C99.7 45.5 100.1 48.7 100.1 52C100.1 55.3 99.8 58.5 99.1 61.7C98.5 64.8 97.5 67.8 96.3 70.7C95.1 73.6 93.6 76.3 91.9 78.9C90.2 81.4 88.2 83.8 86 86C83.9 88.3 81.5 90.3 78.9 92C76.3 93.7 73.6 95.2 70.7 96.4C67.8 97.6 64.7 98.6 61.7 99.2C58.5 99.8 55.3 100.2 52 100.2C48.7 100.2 45.5 99.9 42.3 99.2C39.2 98.6 36.2 97.6 33.3 96.4C30.4 95.2 27.7 93.7 25.1 92C22.6 90.3 20.2 88.3 18 86.1C15.8 83.9 13.8 81.5 12.1 79C10.4 76.4 8.9 73.7 7.7 70.8C6.5 67.9 5.5 64.8 4.9 61.8C4.3 58.6 3.9 55.4 3.9 52.1C3.9 48.8 4.2 45.6 4.9 42.4C5.5 39.3 6.5 36.3 7.7 33.4C8.9 30.5 10.4 27.8 12.1 25.2C13.8 22.7 15.8 20.3 18 18.1C20.2 15.9 22.6 13.9 25.1 12.2C27.7 10.5 30.4 9 33.3 7.8C36.2 6.6 39.3 5.6 42.3 5C45.5 4.2 48.7 3.9 52 3.9ZM52 2C24.4 2 2 24.4 2 52C2 79.6 24.4 102 52 102C79.6 102 102 79.6 102 52C102 24.4 79.6 2 52 2Z" fill="white"/>
<path d="M27.3997 73C25.1997 73 23.0997 73.4 21.1997 74.1C21.4997 74.7 21.7997 75.3 22.0997 75.9C23.6997 75.3 25.4997 75 27.3997 75C35.3997 75 41.8997 81.1 41.8997 88.5C41.8997 89.5 41.7997 90.4 41.5997 91.3C42.2997 91.5 42.8997 91.7 43.5997 91.9C43.7997 90.8 43.9997 89.7 43.9997 88.6C43.9997 79.9 36.5997 73 27.3997 73Z" fill="white"/>
<path d="M27.3998 61.7C24.1998 61.7 21.1998 62.1999 18.2998 63.0999C18.2998 63.7999 18.3998 64.3999 18.4998 65.0999C21.2998 64.1999 24.1998 63.6999 27.2998 63.6999C41.9998 63.6999 53.8998 74.7999 53.8998 88.5999C53.8998 90.0999 53.7998 91.5 53.4998 92.9C54.1998 92.9 54.8998 92.7999 55.5998 92.6999C55.7998 91.3999 55.9998 90 55.9998 88.5C56.0998 73.7 43.2998 61.7 27.3998 61.7Z" fill="white"/>
<path d="M62.4999 91.2999C62.2999 90.3999 62.1999 89.4999 62.1999 88.4999C62.1999 80.9999 68.6999 74.9999 76.6999 74.9999C78.5999 74.9999 80.2999 75.2999 81.9999 75.8999C82.2999 75.2999 82.5999 74.6999 82.8999 74.0999C80.8999 73.3999 78.8999 72.9999 76.6999 72.9999C69.9999 72.9999 64.1999 76.7999 61.5999 82.1999C61.1999 80.4999 60.6999 78.7999 60.0999 77.1999C63.7999 72.3999 69.8999 69.2999 76.6999 69.2999C79.3999 69.2999 81.9999 69.7999 84.2999 70.6999C84.4999 70.0999 84.6999 69.4999 84.7999 68.7999C82.2999 67.8999 79.4999 67.3999 76.6999 67.3999C69.5999 67.3999 63.2999 70.4999 59.1999 75.1999C58.5999 73.9999 57.9999 72.7999 57.1999 71.6999C62.0999 66.7999 68.9999 63.6999 76.6999 63.6999C79.7999 63.6999 82.7999 64.1999 85.4999 65.0999C85.5999 64.3999 85.5999 63.7999 85.6999 63.0999C82.7999 62.1999 79.7999 61.6999 76.5999 61.6999C68.4999 61.6999 61.0999 64.8999 55.8999 69.9999C55.0999 68.9999 54.2999 67.9999 53.3999 66.9999C59.2999 61.3999 67.4999 57.8999 76.5999 57.8999C79.6999 57.8999 82.6999 58.2999 85.5999 59.0999C85.4999 58.3999 85.4999 57.6999 85.3999 56.9999C82.5999 56.2999 79.6999 55.8999 76.5999 55.8999C66.9999 55.8999 58.2999 59.5999 51.9999 65.4999C45.6999 59.5999 36.9999 55.8999 27.3999 55.8999C24.3999 55.8999 21.3999 56.2999 18.5999 56.9999C18.4999 57.6999 18.3999 58.3999 18.3999 59.0999C21.2999 58.2999 24.2999 57.8999 27.3999 57.8999C45.3999 57.8999 60.0999 71.5999 60.0999 88.3999C60.0999 89.5999 59.9999 90.6999 59.8999 91.8999C60.7999 91.6999 61.5999 91.4999 62.4999 91.2999Z" fill="white"/>
<path d="M27.3998 67.2998C24.4998 67.2998 21.7998 67.7998 19.2998 68.6998C19.4998 69.2998 19.6998 69.9998 19.7998 70.5998C22.1998 69.6998 24.6998 69.1998 27.3998 69.1998C38.6998 69.1998 47.8998 77.7998 47.8998 88.3998C47.8998 89.7998 47.6998 91.0998 47.3998 92.4998C48.0998 92.5998 48.7998 92.5998 49.4998 92.6998C49.7998 91.2998 49.9998 89.8998 49.9998 88.3998C50.0998 76.7998 39.8998 67.2998 27.3998 67.2998Z" fill="white"/>
<path d="M12.6 47.4C10.2 46.7 8.99999 44.4 9.69999 42C10.1 40.5 11 39.6 12.3 39.2L12.8 40.7C12.1 40.9 11.4 41.4 11.2 42.4C10.8 43.9 11.6 45.1999 13.1 45.5999C14.6 45.9999 16.1 45.4 16.5 43.9C16.8 42.8 16.5 42.1 15.9 41.5L17.2 40.5C18.1 41.5 18.5 42.8 18 44.4C17.3 46.8 15 48.1 12.6 47.4Z" fill="white"/>
<path d="M13.2002 33.6L13.3002 33.5L22.6002 34.2L21.7002 35.8L20.7002 35.7L19.0002 38.7L19.6002 39.5L18.9002 41L13.2002 33.6ZM19.1002 35.5L17.5002 35.3C16.9002 35.2 15.9002 35 15.9002 35C15.9002 35 16.6002 35.7 17.0002 36.2L18.0002 37.4L19.1002 35.5Z" fill="white"/>
<path d="M27.3999 28.8L26.0999 30.3L23.4999 30C23.3999 30.1 23.2999 30.3 23.1999 30.4L22.7999 30.9L24.3999 32.3L23.2999 33.6L16.8999 27.9L18.3999 26.1C19.9999 24.3 21.6999 24 23.2999 25.4C24.2999 26.3 24.4999 27.3 24.1999 28.4L27.3999 28.8ZM21.9999 29.4C22.8999 28.3 22.9999 27.6 22.0999 26.8C21.1999 26 20.3999 26.2 19.4999 27.2L19.0999 27.6L21.5999 29.8L21.9999 29.4Z" fill="white"/>
<path d="M33.3002 24.2999L31.7002 25.4999L29.3002 24.5999C29.2002 24.6999 29.1002 24.7999 28.9002 24.8999L28.4002 25.2999L29.7002 26.9999L28.3002 27.9999L23.2002 21.1999L25.1002 19.7999C27.0002 18.3999 28.8002 18.3999 30.0002 20.0999C30.8002 21.1999 30.8002 22.1999 30.3002 23.1999L33.3002 24.2999ZM27.9002 23.7999C29.0002 22.9999 29.3002 22.1999 28.5002 21.2999C27.8002 20.2999 27.0002 20.2999 25.9002 21.1999L25.5002 21.4999L27.5002 24.0999L27.9002 23.7999Z" fill="white"/>
<path d="M30.7998 16.1L35.8998 13.7L36.4998 15.1L32.9998 16.7L34.0998 19.1L37.1998 17.7L37.7998 19L34.6998 20.4L35.1998 21.5L38.7998 19.8L39.3998 21.2L34.1998 23.6L30.7998 16.1Z" fill="white"/>
<path d="M39.2002 12.3H39.3002L44.3002 16L47.4002 10.7H47.5002L49.2002 19.1L47.6002 19.4L47.0002 16.3C46.9002 15.7 46.8002 14.8 46.8002 14.8C46.8002 14.8 46.4002 15.6 46.1002 16.1L44.8002 18.3H44.6002L42.6002 16.8C42.1002 16.4 41.5002 15.8 41.5002 15.8C41.5002 15.8 41.7002 16.7 41.9002 17.2L42.5002 20.3L40.9002 20.6L39.2002 12.3Z" fill="white"/>
<path d="M51.1002 10.7L56.7002 10.9L56.6002 12.4L52.8002 12.2L52.7002 14.9L56.1002 15L56.0002 16.5L52.6002 16.4L52.5002 17.6L56.5002 17.8L56.4002 19.3L50.7002 19.1L51.1002 10.7Z" fill="white"/>
<path d="M61.2999 16.4C60.9999 15.9 60.5999 15 60.5999 15C60.5999 15 60.4999 15.9 60.2999 16.5L59.3999 19.9L57.8999 19.5L60.0999 11.3H60.2999L63.3999 16.3C63.6999 16.8 64.0999 17.7 64.0999 17.7C64.0999 17.7 64.1999 16.7 64.3999 16.2L65.2999 12.8L66.8999 13.2L64.6999 21.4H64.4999L61.2999 16.4Z" fill="white"/>
<path d="M70.8998 16.7L68.7998 15.6L69.4998 14.3L75.2998 17.3L74.5998 18.6L72.4998 17.5L69.2998 23.6L67.7998 22.8L70.8998 16.7Z" fill="white"/>
<path d="M80.4001 21.0999L82.8001 23.3999C83.9001 24.4999 83.9001 25.7999 83.0001 26.6999C82.5001 27.2999 81.8001 27.2999 81.1001 26.9999C81.7001 27.9999 81.6001 28.8999 80.9001 29.6999C79.9001 30.7999 78.3001 30.7999 77.1001 29.5999L74.6001 27.1999L80.4001 21.0999ZM78.0001 28.3999C78.5001 28.8999 79.2001 28.8999 79.6001 28.3999C80.1001 27.8999 80.1001 27.1999 79.5001 26.6999L78.4001 25.6999L76.9001 27.2999L78.0001 28.3999ZM80.5001 25.4999C80.9001 25.8999 81.4001 25.8999 81.7001 25.5999C82.0001 25.1999 82.0001 24.7999 81.6001 24.3999L80.6001 23.3999L79.5001 24.4999L80.5001 25.4999Z" fill="white"/>
<path d="M83.1999 29.8C85.2999 28.5 87.8999 29.1 89.1999 31.2C90.4999 33.3 89.9999 35.9 87.8999 37.2C85.7999 38.5 83.1999 37.9 81.8999 35.8C80.5999 33.7 81.0999 31.1 83.1999 29.8ZM86.9999 35.6C88.2999 34.8 88.7999 33.2 87.9999 31.9C87.1999 30.6 85.5999 30.4 84.2999 31.2C82.9999 32 82.4999 33.6 83.2999 34.9C84.0999 36.2 85.5999 36.5 86.9999 35.6Z" fill="white"/>
<path d="M89.2998 41.7C89.4998 41.2 90.0998 40.4 90.0998 40.4C90.0998 40.4 89.2998 40.8 88.6998 41L85.2998 42.1L84.7998 40.5L92.8998 38L92.9998 38.2L90.4998 43.5C90.2998 44 89.6998 44.8 89.6998 44.8C89.6998 44.8 90.5998 44.4 91.0998 44.2L94.4998 43.1L94.9998 44.7L86.8998 47.3L86.7998 47L89.2998 41.7Z" fill="white"/>
<path d="M50.0001 35.4999C49.9001 35.3999 49.8001 35.3999 49.7001 35.3999C49.5001 35.3999 49.2001 35.4999 49.1001 35.6999C48.9001 35.9999 49.0001 36.3999 49.3001 36.5999C49.4001 36.6999 49.5001 36.6999 49.6001 36.6999C49.8001 36.6999 50.1001 36.5999 50.2001 36.3999C50.4001 36.0999 50.3001 35.6999 50.0001 35.4999Z" fill="white"/>
<path d="M45.2002 39.8999C45.1002 39.8999 45.0002 39.8999 44.9002 39.9999C44.6002 40.1999 44.5002 40.5999 44.7002 40.8999C44.8002 41.0999 45.0002 41.1999 45.3002 41.1999C45.4002 41.1999 45.5002 41.1999 45.6002 41.0999C45.7002 40.9999 45.9002 40.8999 45.9002 40.6999C45.9002 40.4999 45.9002 40.2999 45.8002 40.1999C45.6002 39.9999 45.4002 39.8999 45.2002 39.8999Z" fill="white"/>
<path d="M46.8001 47.4C47.1867 47.4 47.5001 47.0866 47.5001 46.7C47.5001 46.3134 47.1867 46 46.8001 46C46.4135 46 46.1001 46.3134 46.1001 46.7C46.1001 47.0866 46.4135 47.4 46.8001 47.4Z" fill="white"/>
<path d="M53.3004 47.7999C53.2004 47.6999 53.1004 47.7 53.0004 47.7C52.8004 47.7 52.5004 47.8 52.4004 48C52.2004 48.3 52.3004 48.7 52.6004 48.9C52.7004 49 52.8004 49 52.9004 49C53.1004 49 53.4004 48.9 53.5004 48.7C53.6004 48.6 53.6004 48.4 53.6004 48.2C53.5004 48 53.4004 47.7999 53.3004 47.7999Z" fill="white"/>
<path d="M57.4003 43.2C57.3003 43.2 57.2003 43.1999 57.1003 43.2999C57.0003 43.3999 56.8003 43.5 56.8003 43.7C56.8003 43.9 56.8003 44.1 56.9003 44.2C57.0003 44.4 57.2003 44.5 57.5003 44.5C57.6003 44.5 57.7003 44.5 57.8003 44.4C57.9003 44.3 58.1003 44.2 58.1003 44C58.1003 43.8 58.1003 43.6 58.0003 43.5C57.9003 43.3 57.7003 43.2 57.4003 43.2Z" fill="white"/>
<path d="M65.0001 40.9C65.0001 40.5 64.7001 40.2 64.4001 40.2C63.5001 40.2 62.6001 39.6 62.2001 38.8C62.1001 38.6 62.0001 38.5 61.8001 38.4C61.6001 38.4 61.4001 38.4 61.3001 38.5C60.9001 38.8 60.4001 39 59.9001 39C59.5001 39 59.0001 38.9 58.7001 38.7C57.9001 38.2 57.4001 37.3 57.5001 36.3C57.5001 36.1 57.5001 35.9 57.3001 35.8C57.2001 35.7 57.0001 35.6 56.8001 35.6C56.7001 35.6 56.7001 35.6 56.6001 35.6C55.7001 35.6 54.9001 35.1 54.4001 34.4C53.9001 33.6 54.0001 32.5 54.5001 31.7C54.6001 31.6 54.6001 31.3 54.6001 31.2C54.6001 31 54.4001 30.9 54.2001 30.8C53.4001 30.4 52.8001 29.6 52.8001 28.6C52.8001 28.3 52.5001 28 52.2001 28H52.1001C44.9001 28 39.1001 33.9 39.1001 41C39.1001 48.1 45.0001 54 52.1001 54C59.2001 54 65.0001 48.2 65.0001 40.9C65.0001 41 65.0001 41 65.0001 40.9ZM51.9001 52.1C45.8001 52.1 40.9001 47.2 40.9001 41.1C40.9001 35.1 45.4001 30.5 51.3001 30.3C51.5001 31.2 52.0001 31.9 52.7001 32.4C52.2001 33.4 52.3001 34.6 52.8001 35.6C53.3001 36.6 54.4001 37.2 55.5001 37.3C55.6001 38.4 56.2001 39.5 57.2001 40C57.7001 40.3 58.3001 40.5 59.0001 40.5C59.5001 40.5 60.0001 40.4 60.5001 40.2C61.0001 40.9 61.8001 41.4 62.6001 41.6C62.5001 47.6 57.9001 52.1 51.9001 52.1Z" fill="white"/>
</svg>`