import { Component, OnInit } from "@angular/core";
import { Order } from "../../_commons/models/order";
import { OrderedProduct } from "../../_commons/models/ordered-product";
import { AccountService } from "../../_commons/services/account/account.service";
import { ApiOrderService } from "../../_commons/services/api/api-order/api-order.service";
import { OrderItemComponent } from "./order-item/order-item.component";
@Component({
	selector: "app-order",
	standalone: true,
	imports: [OrderItemComponent],
	templateUrl: "./order.component.html",
	styleUrl: "./order.component.scss",
})
export class OrderComponent implements OnInit {
	public orders: Order[] = [];

	constructor(
		private _account: AccountService,
		private _order: ApiOrderService
	) {}

	public async ngOnInit(): Promise<void> {
		const token: string | null = this._account.getUserToken();
		this.orders = (await this._order.getAllByUser(token!))?.body || [];

		this.orders.map((order: Order) => {
			order.cart.orderedProducts.map((orderedProduct: OrderedProduct) => {
				orderedProduct.amount =
					orderedProduct.quantity * orderedProduct.price.value;
			});

			order.date = new Date(order.date);
		});
	}
}
