<footer class="cb-footer">
	<mat-accordion class="cb-mat-accordion">
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-title class="cb-title">catégories</mat-panel-title>
			</mat-expansion-panel-header>

			@for (category of this.categories; track $index) {
				<a class="cb-text" [routerLink]="[this.sitemap.shop.route, category.slug]">{{ category.name }}</a>
			}
		</mat-expansion-panel>
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-title class="cb-title">à propos</mat-panel-title>
			</mat-expansion-panel-header>
			<a class="cb-text" [routerLink]="[this.sitemap.faq.route]">faq</a>
			<a class="cb-text" [routerLink]="[this.sitemap.contact.route]" href="">contactez-nous </a>
		</mat-expansion-panel>

		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-title class="cb-title">Conditions légales</mat-panel-title>
			</mat-expansion-panel-header>
			<a class="cb-text" [routerLink]="[this.sitemap.conditions.route]" href="">condition générales</a>
			<a class="cb-text" [routerLink]="[this.sitemap.legalNotice.route]" href="">mentions légales </a>
		</mat-expansion-panel>
	</mat-accordion>

	<div class="cb-column-container">
		<div class="cb-column">
			<h5 class="cb-title">catégories</h5>

			@for (category of this.categories; track $index) {
				<a class="cb-text" [routerLink]="[this.sitemap.shop.route, category.slug]">{{ category.name }}</a>
			}
		</div>
		<div class="cb-column">
			<h5 class="cb-title">à propos</h5>
			<a class="cb-text" [routerLink]="[this.sitemap.faq.route]">faq</a>
			<a class="cb-text" [routerLink]="[this.sitemap.contact.route]"> contactez-nous </a>
		</div>
		<div class="cb-column">
			<h5 class="cb-title">conditions légales</h5>
			<a class="cb-text" [routerLink]="[this.sitemap.conditions.route]">condition générales</a>
			<a class="cb-text" [routerLink]="[this.sitemap.legalNotice.route]"> mentions légales </a>
		</div>
		<div class="cb-column">
			<h5 class="cb-title">réseaux</h5>
			<a class="cb-text" href="https://www.instagram.com/carrementbonbiscuits/?hl=fr" target="_blank">instagram</a>
			<a class="cb-text" href="https://www.facebook.com/carrementbonbiscuits/" target="_blank">facebook</a>
		</div>
	</div>

	<div class="cb-logo">
		<div class="cb-logo-ctnr">
			<img class="cb-logo-img" src="/assets/imgs/HERO/logo-hd.svg" alt="logo-hd.svg" [draggable]="false" />
		</div>
	</div>

	<div class="cb-bottom-content">
		<div class="cb-footer-bottom">
			<div class="cb-socials">
				<a class="cb-text" href="https://www.instagram.com/carrementbonbiscuits/?hl=fr" target="_blank"> INSTAGRAM </a>
				<a class="cb-text" href="https://www.facebook.com/carrementbonbiscuits/" target="_blank"> FACEBOOK </a>
			</div>
			<div class="cb-copyright-ctnr">
				<h6 class="cb-copyright">Développé par ASC-IT</h6>
			</div>
		</div>
	</div>
</footer>
