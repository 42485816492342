import { Component, Inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import 'moment/locale/fr';

import { SITEMAP, SiteMap } from '../../_commons/sitemap';
import { MailDto } from '../../_commons/dtos/mail.dto';
import { SignUpDto } from '../../_commons/dtos/sign-up.dto';

import { SignService } from '../../_commons/services/sign/sign.service';
import { ApiAuthService } from '../../_commons/services/api/api-auth/api-auth.service';

import { CbButtonComponent } from '../../_commons/components/cb-button/cb-button.component';
import { CbModalComponent } from '../../_commons/components/cb-modal/cb-modal.component';
import { AccountService } from '../../_commons/services/account/account.service';

@Component({
	selector: 'app-sign-up-process',
	standalone: true,
	imports: [
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatNativeDateModule,
		MatDatepickerModule,
		MatRadioModule,
		MatFormFieldModule,
		MatInputModule,
		MatDatepickerModule,
		MatIconModule,
		MatCheckboxModule,
		CbButtonComponent
	],
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
		provideMomentDateAdapter()
	],
	templateUrl: './sign-up-process.component.html',
	styleUrl: './sign-up-process.component.scss'
})
export class SignUpProcessComponent {

	public sitemap: SiteMap = SITEMAP;
	public sendingCode: boolean = false;
	public submitted: boolean = false;
	public email: string = '';
	public visibility: boolean = false;

	public signUpForm: FormGroup = this.fb.group({
		gender: [null],
		firstName: [null, Validators.required],
		lastName: [null, Validators.required],
		birthday: [null, [ Validators.required ]],
		password: [null, [ Validators.required, Validators.minLength(8) ]],
		code: [null, [ Validators.required, Validators.pattern(/^[0-9]{6}$/) ]],
		newsletter: [false],
		cgv: [false, Validators.requiredTrue]
	});

	constructor(
		private dialog: MatDialog,
		private fb: FormBuilder,
		private router: Router,
		private _auth: ApiAuthService,
		private _account: AccountService,
		private _sign: SignService,
		private adapter: DateAdapter<any>,
		@Inject(MAT_DATE_LOCALE) private locale: string
	) {
		this.locale = 'fr';
		this.adapter.setLocale(this.locale);

		this._sign.currentMailAddress.subscribe((email: string | null) => this.email = email || '');

		if(this.email)
			this.sendCode();

		else
			this.router.navigate([ SITEMAP.sign.route ]);
	}

	public async sendCode(): Promise<void> {

		this.sendingCode = true;
		this.signUpForm.disable()

		const codeSent: boolean = (await this._auth.sendCode(<MailDto>{ email: this.email }))?.body?.success || false;

		if(!codeSent)
			this.dialog.open(CbModalComponent, {
				data: {
					title: 'Erreur',
					message: 'Échec lors de l\'envoi du code de confirmation sur votre adresse e-mail',
					buttonText: 'fermer'
				}
			});

		this.sendingCode = false;
		this.signUpForm.enable()

	}

	public async signUpProcess(): Promise<void> {
		if(this.signUpForm.invalid)
			return;

		this.submitted = true;

		const signUpDto: SignUpDto = {
			...this.signUpForm.value,
			email: this.email,
			birthday: new Date(Date.parse(this.signUpForm.value.birthday)).toLocaleDateString('fr')
		};

		const success: boolean = (await this._account.register(signUpDto))

		if(success) {
			this._sign.resetMailAddress();
			this.router.navigate([SITEMAP.account.route]);
		}

		else
			this.dialog.open(CbModalComponent, {
				data: {
					title: 'Échec de la procédure d\'inscription',
					message: 'Les informations du formulaire d\'inscription que vous venez de renseigner semblent incorrectes',
					buttonText: 'fermer'
				}
			})

		this.submitted = false;
	}

}
