import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { Category } from '../../_commons/models/category';

import { CategoryService } from '../../_commons/services/category/category.service';

import { AdminCategoriesModalComponent } from './admin-categories-modal/admin-categories-modal.component';

@Component({
	selector: 'app-admin-categories',
	standalone: true,
	imports: [
		MatButtonModule,
		MatCardModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatTooltipModule,
	],
	templateUrl: './admin-categories.component.html',
	styleUrl: './admin-categories.component.scss'
})
export class AdminCategoriesComponent implements OnInit {

	public categories: Category[] = [];

	public isLoading: boolean = false;

	constructor(
		private dialog: MatDialog,
		private sb: MatSnackBar,
		private _category: CategoryService
	) {}

	private async getCategories(): Promise<void> {
		this.isLoading = true;
		this.categories = await this._category.getCategories();
		this.isLoading = false;
	}

	public async ngOnInit(): Promise<void> {
		await this.getCategories();
	}

	public openDialog(catergory?: Category): void {
		this.dialog
			.open(AdminCategoriesModalComponent, {
				disableClose: true,
				data: catergory
			})
			.afterClosed().subscribe(async (data) => {
				if(data.success)
					await this.getCategories();
			});
	}

	public async delete(category: Category): Promise<void> {
		const deletion: boolean = await this._category.delete(category);

		if(deletion) {
			this.sb.open(`Catégorie ${category.name} supprimée`, "Fermer", {
				duration: 3000,
					horizontalPosition: 'end',
					verticalPosition: 'top'
			});

			await this.getCategories();
			return;
		}

		this.sb.open("Erreur, la catégorie n'est pas vide", "Fermer", {
			duration: 3000,
				horizontalPosition: 'end',
				verticalPosition: 'top'
		});
	}

}
