import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Role } from '../../models/role';

import { ApiRoleService } from '../api/api-role/api-role.service';
import { AccountService } from '../account/account.service';

@Injectable({
	providedIn: 'root'
})
export class RoleService {

	private token: string = '';

	private approvalRoles: BehaviorSubject<Role[]> = new BehaviorSubject<Role[]>([]);
	public currentRoles: Observable<Role[]> = this.approvalRoles.asObservable();

	constructor(
		private _account: AccountService,
		private _role: ApiRoleService
	) {
		this._account.currentUserToken.subscribe((token: string | null) => this.token = token || '');

		this.getRoles();
	}

	public async getRoles(): Promise<Role[]> {
		if(!this.approvalRoles.getValue().length) {
			const orderStatuses: Role[] = (await this._role.getAll(this.token))?.body || [];
			this.approvalRoles.next(orderStatuses);
		}

		return this.approvalRoles.getValue();
	}

}
