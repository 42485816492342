import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder, Validators } from  '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

import { MailDto } from '../../_commons/dtos/mail.dto';

import { ApiAuthService } from '../../_commons/services/api/api-auth/api-auth.service';
import { SignService } from '../../_commons/services/sign/sign.service';

import { CbButtonComponent } from '../../_commons/components/cb-button/cb-button.component';
import { CbModalComponent } from '../../_commons/components/cb-modal/cb-modal.component';
import { SITEMAP } from '../../_commons/sitemap';

@Component({
	selector: 'app-sign-up',
	standalone: true,
	imports: [
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		CbButtonComponent
	],
	templateUrl: './sign-up.component.html',
	styleUrl: './sign-up.component.scss'
})
export class SignUpComponent {

	public submitted: boolean = false;
	public email: string = '';

	public signUpForm: FormGroup = this.fb.group({
		email: [null, [ Validators.required, Validators.email ]],
	});

	constructor(
		public dialog: MatDialog,
		private fb: FormBuilder,
		private router: Router,
		private _auth: ApiAuthService,
		private _sign: SignService
	) {
		this._sign.currentMailAddress.subscribe((email: string | null) => this.email = email || '')
	}

	public async signUp(): Promise<void> {
		if(this.signUpForm.invalid)
			return;

		this.submitted = true;

		const mailExist: boolean = (await this._auth.checkMail(<MailDto>this.signUpForm.value))?.body?.success || false;

		if(!mailExist) {
			this._sign.updateMailAddress(this.signUpForm.value.email);
			this.router.navigate([SITEMAP.signUpProcess.route]);
		}

		else
			this.dialog.open(CbModalComponent, {
				data: {
					title: 'Échec de la procédure d\'inscription',
					message: 'Un compte portant la même adresse e-mail existe déjà',
					buttonText: 'fermer'
				}
			});

		this.submitted = false;
	}

}
