<h2 class="cb-title">Paramètres</h2>

<div class="cb-admin-settings">

	@for (link of this.links; track link) {
		<button
			mat-flat-button
			class="cb-admin-settings-button"
			[routerLink]="[ link.src ]"
		>
			<mat-icon>{{ link.icon }}</mat-icon>
			<p>{{ link.name }}</p>
		</button>
	}

</div>