import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { DomSanitizer } from "@angular/platform-browser";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatTooltipModule } from "@angular/material/tooltip";

import { SHOP_ADDRESSES } from "../../_commons/constants";
import { Address } from "../../_commons/models/address";
import { OrderedProduct } from "../../_commons/models/ordered-product";
import { User } from "../../_commons/models/user";

import { CbButtonComponent } from "../../_commons/components/cb-button/cb-button.component";
import { SummaryCartComponent } from "../summary-cart/summary-cart.component";

import { AccountService } from "../../_commons/services/account/account.service";
import { ApiDeliveryService } from "../../_commons/services/api/api-delivery/api-delivery.service";
import { CartService } from "../../_commons/services/cart/cart.service";
import { CheckoutService } from "../../_commons/services/checkout/checkout.service";

import { CbAddressSelectorComponent } from "../../_commons/components/cb-address-selector/cb-address-selector.component";
import { SITEMAP, SiteMap } from "../../_commons/sitemap";
import { MondialRelayPoint } from "../../_commons/models/mondial-relay/mondial-relay-point";
import { MondialRelayPointAddress } from "../../_commons/models/mondial-relay/mondial-relay-point-address";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CbMapComponent } from "../../_commons/components/cb-map/cb-map.component";
import { CbMapDataService } from "../../_commons/services/cb-map-data-service/cb-map-data.service";
@Component({
	selector: "app-checkout-start",
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatTooltipModule,
		CbButtonComponent,
		SummaryCartComponent,
		CbAddressSelectorComponent,
		CbMapComponent,
	],
	templateUrl: "./checkout-start.component.html",
	styleUrl: "./checkout-start.component.scss",
})
export class CheckoutStartComponent implements OnInit {
	@ViewChild("cbTop", { static: false }) public cbTop?: ElementRef;
	@ViewChild("mapContainer", { static: false }) public mapContainer!: ElementRef;

	private token: string = "";
	public sitemap: SiteMap = SITEMAP;
	public relayAddresses: Address[] = [];
	public addresses: Address[] = [];
	public user!: User;
	public isLoading: boolean = false;
	public options: number = 1;
	public cartItems: OrderedProduct[] = [];

	public relayAddress!: Address;
	public personnalAddress!: Address;
	public deliveredAddress!: Address;

	public shopAddresses: Address[] = SHOP_ADDRESSES;

	public zip: string = "";
	public city: string = "";
	public street: string = "";

	constructor(
		private _account: AccountService,
		private _checkout: CheckoutService,
		private _cart: CartService,
		private _delivery: ApiDeliveryService,
		private _mapData: CbMapDataService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		public dialog: MatDialog
	) {
		this.matIconRegistry.addSvgIcon("colissimo", this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/checkout/colissimo.svg"));
		this.matIconRegistry.addSvgIcon("mondialrelay", this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/checkout/mondial-relay.svg"));
	}

	public ngOnInit(): void {
		this.initializeUserDetails();
		this.cartItems = this._cart.getCartItems();
		this._mapData.setPersonnalAddress(this.personnalAddress);
		this._mapData.setIsLoading(this.isLoading);
		this._mapData.setRelayAddresses(this.relayAddresses);
	}

	private initializeUserDetails(): void {
		this._account.currentUser.subscribe((user: User | null) => {
			this.user = user!;

			if (this.user) {
				this.addresses = this.user.profile.addresses;
				this.personnalAddress = this.addresses.find((address: Address) => address.isDefault)!;

				if (this.personnalAddress) {
					this.city = this.personnalAddress.city;
					this.zip = this.personnalAddress.zip;
					this.street = this.personnalAddress.street;
				}

				this._checkout.currentDeliveredAddress.subscribe((address: Address | null) => {
					this.deliveredAddress = address!;

					if (this.deliveredAddress && this.isNotKnownAddress(this.deliveredAddress)) {
						this.options = 2;
					}

					if (this.deliveredAddress && this.isShopAddresses(this.deliveredAddress)) {
						this.options = 3;
					}

					if (this.deliveredAddress && this.isShopAddresses(this.deliveredAddress)) {
						this.options = 4;
					}

					if (!this.deliveredAddress && this.personnalAddress) {
						this.updateDeliveredAddress(this.personnalAddress);
					}
				});
			}
		});

		this._account.currentUserToken.subscribe((token: string | null) => {
			this.token = token || "";
		});
	}

	public async relayPointSelected(zip: string, city: string): Promise<void> {
		this.isLoading = true;
		this.relayAddresses = [];
		const relays: MondialRelayPoint = (await this._delivery.getMondialRelayPoint(zip, city))?.body!;

		relays.PRList.map((a: MondialRelayPointAddress) => {
			if (a.Available) {
				this.relayAddresses.push({
					firstName: a.Nom,
					lastName: "",
					street: a.Adresse1,
					zip: a.CP,
					city: a.Ville,
					country: a.Pays,
					phone: "",
					MRID: `${a.Pays}-${a.ID}`,
					lat: +a.Lat.replace(",", "."),
					long: +a.Long.replace(",", "."),
					isDefault: false,
					isBilledAddress: false,
					isDefaultBilledAddress: false,
				});
			}
		});
		this.isLoading = false;
	}

	private isNotKnownAddress = (address: Address): boolean =>
		!this.addresses.map((a: Address) => JSON.stringify(address) === JSON.stringify(a)).find((v: boolean) => v === true);
	private isShopAddresses = (address: Address): boolean => this.shopAddresses.find((sa: Address) => JSON.stringify(address) === JSON.stringify(sa)) ? true : false;

	public updateDeliveredAddress(address: Address): void {
		this.deliveredAddress = address;
		this._checkout.setDeliveredAddress(address);
	}
}
