import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialog } from '@angular/material/dialog';

import { COUNTRIES } from '../../../country';
import { Address } from '../../../models/address';
import { Country } from '../../../models/country';
import { User } from '../../../models/user';

import { ApiAddressService } from '../../../services/api/api-address/api-address.service';
import { AccountService } from '../../../services/account/account.service';

import { CbAddressFormComponent } from '../cb-address-form/cb-address-form.component';

@Component({
	selector: 'app-cb-address-item',
	standalone: true,
	imports: [
		MatButtonModule,
		MatIconModule,
		MatTooltipModule
	],
	templateUrl: './cb-address-item.component.html',
	styleUrl: './cb-address-item.component.scss'
})
export class CbAddressItemComponent implements OnInit {

	@Input() public address!: Address;
	@Input() public disabled: boolean = false;
	@Input() public options: boolean = false;

	@Input() public beforeDelete: (args: any) => void = () => {};
	@Input() public afterDelete: (args: any) => void = () => {};

	public countries: Country[] = COUNTRIES;

	public deletionProgress: boolean = false;
	public country: string = '';

	private user!: User;
	private token: string = '';

	constructor(
		public dialog: MatDialog,
		public _address: ApiAddressService,
		public _account: AccountService
	) {
		this._account.currentUser.subscribe((user: User | null) => this.user = user!);
		this._account.currentUserToken.subscribe((token: string | null) => this.token = token || '');
	}

	public ngOnInit(): void {
		this.country = this.countries.find((country: Country) => country.value === this.address.country)?.name || '';
	}

	public async deleteAddress(): Promise<void> {
		this.beforeDelete(this.address);
		this.deletionProgress = true;

		try {
			await this._address.delete(this.token, this.address.id!);
			this.user.profile.addresses = this.user.profile.addresses.filter((a: Address) => a.id !== this.address.id);
			this._account.updateAccount(this.user, this.token);
		}

		catch (error) {
			console.error(error);
		}

		this.deletionProgress = false;
		this.afterDelete(this.address);
	}

	public openDialog(): void {
		this.dialog.open(CbAddressFormComponent, {
			disableClose: true,
			data: this.address
		});
	}

}
