import { Component, OnInit } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatTooltipModule } from "@angular/material/tooltip";

import { SITEMAP, SiteMap } from "../../_commons/sitemap";
import { OrderedProduct } from "../../_commons/models/ordered-product";

import { CartService } from "../../_commons/services/cart/cart.service";
import { ToPricePipe } from "../../_commons/pipes/to-price/to-price.pipe";

import { CartItemComponent } from "./cart-item/cart-item.component";
import { CbButtonComponent } from "../../_commons/components/cb-button/cb-button.component";

@Component({
	selector: "app-cart",
	standalone: true,
	imports: [
		RouterModule,
		MatTooltipModule,
		ToPricePipe,
		CartItemComponent,
		CbButtonComponent
	],
	templateUrl: "./cart.component.html",
	styleUrl: "./cart.component.scss",
})
export class CartComponent implements OnInit {

	public sitemap: SiteMap = SITEMAP;
	public cartItems: OrderedProduct[] = [];
	public total: number = 0;

	constructor(private _cartService: CartService) {}

	public ngOnInit(): void {
		this._cartService.currentCart.subscribe((orderedProduct: OrderedProduct[] | null) => {
			this.cartItems = orderedProduct!;
			this.total = this.cartItems.length > 0 ? this.cartSum() : 0;
		});
		this.total = this.cartItems.length > 0 ? this.cartSum() : 0;
	}

	private cartSum = (): number => this.cartItems.map((orderedProduct: OrderedProduct) => orderedProduct.quantity * orderedProduct.price.value).reduce((a: number, b: number) => a + b);

}
